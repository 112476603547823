import React, { useState, useEffect } from "react";
import Stepper from "../../ui/Stepper";
import { formatPrice } from "../../../scripts/generalScript";
import CollapsibleSection from "./CollapsibleSection";
import { useSalesContext } from "../../../contexts/SalesContext";

const useBreakpoint = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkBreakpoint = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkBreakpoint();
    window.addEventListener("resize", checkBreakpoint);
    return () => window.removeEventListener("resize", checkBreakpoint);
  }, []);

  return isMobile;
};

const WaterBottleCard = () => {
  const { selectedSugs, setSelectedSugs } = useSalesContext();
  const isMobile = useBreakpoint();

  const commonContent = {
    image: "/assets/img/landing/cart-full-sug-drops-blue-plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad.png",
    title: "Botellón 20 lt Retornable",
    price: "$14.990",
    pricePerLiter: "$749.5 x lt",
    freeDelivery: "Entrega gratis",
    description: {
      bold: "Recambialo al consumirlo ",
      regular: "o retórnalo si no lo usas."
    }
  };

  const StepperComponent = () => (
    <div className="w-28">
      <Stepper
        value={selectedSugs}
        onChange={setSelectedSugs}
        min={1}
        max={20}
      />
    </div>
  );

  const FreeDeliveryBadge = () => (
    <div className="w-fit px-1 py-0.5 bg-gray-200 rounded-md justify-center items-center inline-flex">
      <div className="text-neutral-800 text-xs font-medium leading-none">
        {commonContent.freeDelivery}
      </div>
    </div>
  );

  const DescriptionBox = ({ className = "" }) => (
    <div className={`bg-zinc-100 rounded-2xl ${className}`}>
      <p className="text-neutral-600 text-sm font-normal leading-none">
        <span className="font-medium">{commonContent.description.bold}</span>
        {commonContent.description.regular}
      </p>
    </div>
  );

  const PriceDisplay = ({ className = "" }) => (
    <div className={`flex-col justify-start items-start ${className}`}>
      <div className="text-gray-800 font-medium">
        {commonContent.price}
      </div>
      <div className="text-neutral-600 text-sm font-normal leading-none">
        {commonContent.pricePerLiter}
      </div>
    </div>
  );

  const MobileLayout = () => (
    <div className="p-2 bg-neutral-50 rounded-3xl flex flex-col gap-4">
      <div className="flex gap-2">
        <img
          className="w-24 h-24 object-contain shrink-0"
          src={commonContent.image}
          alt={commonContent.title}
        />

        <div className="flex flex-col gap-2 min-w-0">
          <div className="text-gray-800 text-base font-medium leading-none">
            {commonContent.title}
          </div>
          <FreeDeliveryBadge />
          <PriceDisplay className="gap-2" />
          <StepperComponent />
        </div>
      </div>
      <DescriptionBox className="px-2" />
    </div>
  );

  const DesktopLayout = () => (
    <div className="p-4 bg-neutral-50 rounded-3xl justify-start items-start gap-6 flex">
      <div className="w-1/3 max-h-full overflow-hidden">
        <img
          className="w-full h-full object-cover"
          src={commonContent.image}
          alt={commonContent.title}
        />
      </div>

      <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
        <div className="flex-col justify-start items-start gap-1 flex">
          <div className="text-gray-800 text-xl font-medium leading-0">
            {commonContent.title}
          </div>
          <FreeDeliveryBadge />
        </div>

        <div className="flex-col justify-start items-start gap-6 flex">
          <PriceDisplay className="gap-0.5" />
          <StepperComponent />
          <DescriptionBox className="px-4" />
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {isMobile ? (
        <div className="pt-4">
          <MobileLayout />
        </div>
      ) : (
        <div className="pt-4">
          <DesktopLayout />
        </div>
      )}
    </div>
  );
};

export default WaterBottleCard;