import React from "react";

const ComingSoon = () => {
  return (
    <div className="min-h-screen w-full bg-blue-800 flex flex-col items-center justify-center p-4">
      {/* Contenedor principal */}
      <div className="max-w-2xl w-full text-center space-y-8">
        {/* Título */}

        {/* Imagen central */}
        <div className="w-full max-w-md mx-auto">
          <img
            src="assets/logos/fluenti-logo-blue-140.svg"
            className="logo-image min-h-40"
            alt="Fluenti Logo"
          />
        </div>

        {/* Mensaje simple */}
        <p className="text-lg text-white">
          Estamos trabajando en algo asombroso, esperanos pronto.
        </p>

      </div>
    </div>
  );
};

export default ComingSoon;
