import React, { createContext, useContext, useState } from "react";
import supabase from "../SupaCredentials";

const InventoryContext = createContext();

export const useInventory = () => useContext(InventoryContext);

export const InventoryProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [jugsData, setJugsData] = useState([]);
  const [inventorySummary, setInventorySummary] = useState({
    total: 0,
    inInventory: 0,
    delivered: 0,
    refillBase: 0,
    forProduction: 0,
    discarded: 0,
  });

  // Esta es la modificación para InventoryContext.jsx
  // Añade o actualiza esta función dentro del componente InventoryProvider

  // Método para obtener todos los botellones con información detallada
  const getAllJugs = async () => {
    try {
      setLoading(true);
      setError(null);

      // Primero obtenemos los botellones con información básica
      const { data: jugsData, error: jugsError } = await supabase
        .from("jugs")
        .select(
          `
        id, 
        barcode, 
        nfc_code, 
        capacity_liters, 
        material, 
        physical_state, 
        operational_state, 
        refill_count, 
        purchase_date,
        manufacturing_date,
        current_user_id,
        last_delivery,
        products (id, name, sku_code, unit_price),
        created_at,
        updated_at
      `
        )
        .order("created_at", { ascending: false });

      if (jugsError) throw jugsError;

      // Si no tenemos botellones, retornamos un array vacío
      if (!jugsData || jugsData.length === 0) {
        setJugsData([]);
        return [];
      }

      // Luego obtenemos los detalles de órdenes relacionados con estos botellones
      const { data: ordersData, error: ordersError } = await supabase
        .from("order_details")
        .select(
          `
        id,
        order_id,
        delivered_jug_id,
        returned_jug_id,
        previous_order_detail,
        status,
        detail_type,
        actual_delivery_date,
        orders (
          id,
          user_id,
          short_id,
          user_data:user_id (
            id, 
            first_name, 
            last_name, 
            email, 
            phone
          )
        )
      `
        )
        .in(
          "delivered_jug_id",
          jugsData.map((jug) => jug.id)
        )
        .order("actual_delivery_date", { ascending: false });

      if (ordersError) throw ordersError;

      // Combinamos la información de botellones con los detalles de órdenes
      const enhancedJugsData = jugsData.map((jug) => {
        // Encontramos la orden más reciente para este botellón
        const relatedOrder = ordersData.find(
          (order) => order.delivered_jug_id === jug.id
        );

        // Calculamos días desde la entrega
        const daysSinceDelivery = jug.last_delivery
          ? Math.floor(
              (new Date() - new Date(jug.last_delivery)) / (1000 * 60 * 60 * 24)
            )
          : null;

        // Determinamos estado de consumo
        let consumptionStatus = null;
        if (
          [
            "recently_ordered",
            "on_production",
            "to_be_returned",
            "overdue_for_return",
          ].includes(jug.operational_state) &&
          daysSinceDelivery
        ) {
          if (daysSinceDelivery < 28) {
            consumptionStatus = "En consumo";
          } else if (daysSinceDelivery < 33) {
            consumptionStatus = "Necesita recambio";
          } else {
            consumptionStatus = "Para retornar";
          }
        }

        // Datos del cliente
        const client =
          relatedOrder?.orders?.user_data ||
          (jug.current_user_id ? { id: jug.current_user_id } : null);
          

          console.log('>>>>>>>>>>> current uid', jug.current_user_id,"   >>>>> juf id", jug.id)

        return {
          ...jug,
          daysSinceDelivery,
          consumptionStatus,
          client,
          orderDetail: relatedOrder
            ? {
                id: relatedOrder.id,
                orderId: relatedOrder.order_id,
                status: relatedOrder.status,
                detailType: relatedOrder.detail_type,
                orderShortId: relatedOrder.orders?.short_id,
              }
            : null,
          isWithClient: Boolean(
            jug.current_user_id &&
              [
                "recently_ordered",
                "on_production",
                "to_be_returned",
                "overdue_for_return",
              ].includes(jug.operational_state)
          ),
        };
      });

      setJugsData(enhancedJugsData);
      return enhancedJugsData;
    } catch (error) {
      setError(error.message);
      return [];
    } finally {
      setLoading(false);
    }
  };

  // Método para obtener un botellón específico
  const getJugById = async (id) => {
    try {
      setLoading(true);
      setError(null);

      const { data, error } = await supabase
        .from("jugs")
        .select(
          `
          id, 
          barcode, 
          nfc_code, 
          capacity_liters, 
          material, 
          physical_state, 
          operational_state, 
          refill_count, 
          purchase_date,
          manufacturing_date,
          products (id, name, sku_code, unit_price),
          current_user_id,
          last_delivery,
          production_date,
          expiration_date
        `
        )
        .eq("id", id)
        .single();

      if (error) throw error;

      return data;
    } catch (error) {
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Método para crear un nuevo botellón
  const createJug = async (jugData) => {
    try {
      setLoading(true);
      setError(null);

      // Validamos datos mínimos incluyendo ahora el product_id
      if (
        !jugData.barcode ||
        !jugData.capacity_liters ||
        !jugData.material ||
        !jugData.product_id
      ) {
        throw new Error(
          "Se requieren código de barras, capacidad, material y producto"
        );
      }

      // Verificar que el estado operacional sea válido
      const validState =
        jugData.operational_state === "recently_purchased" ||
        jugData.operational_state === "refill_base";

      if (!validState) {
        jugData.operational_state = "recently_purchased"; // Estado predeterminado si no es válido
      }

      // Ahora incluimos el product_id en la creación del botellón
      const { data, error } = await supabase
        .from("jugs")
        .insert([
          {
            barcode: jugData.barcode,
            nfc_code: jugData.nfc_code,
            capacity_liters: jugData.capacity_liters,
            material: jugData.material,
            purchase_date:
              jugData.purchase_date || new Date().toISOString().split("T")[0],
            manufacturing_date:
              jugData.manufacturing_date ||
              new Date().toISOString().split("T")[0],
            physical_state: jugData.physical_state || "new",
            operational_state: jugData.operational_state,
            product_id: jugData.product_id, // Incluimos el ID del producto
          },
        ])
        .select();

      if (error) throw error;

      // Actualizamos la lista de botellones
      await getAllJugs();
      await getInventorySummary();

      return data[0];
    } catch (error) {
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Método para actualizar un botellón existente
  // Método para actualizar un botellón existente
  const updateJug = async (id, updateData) => {
    try {
      setLoading(true);
      setError(null);

      console.log("Datos de actualización:", updateData);

      const { data, error } = await supabase
        .from("jugs")
        .update(updateData)
        .eq("id", id)
        .select();

      if (error) {
        console.error("Error de Supabase:", error);
        throw error;
      }

      console.log("Datos actualizados:", data);

      // Actualizamos la lista de botellones
      await getAllJugs();
      await getInventorySummary();

      return data[0];
    } catch (error) {
      console.error("Error en updateJug:", error.message);
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Método para obtener un resumen del inventario
  const getInventorySummary = async () => {
    try {
      setLoading(true);
      setError(null);

      // Obtener los jugs desde la base de datos
      const { data: jugs, error: jugsError } = await supabase
        .from("jugs")
        .select("id, physical_state, operational_state")
        .eq("active", true);

      if (jugsError) throw jugsError;

      // Si no hay datos, devolver un resumen vacío
      const summary = {
        total: jugs?.length || 0,
        inInventory:
          jugs?.filter((j) => j.operational_state === "in_inventory").length ||
          0,
        delivered:
          jugs?.filter((j) => j.operational_state === "delivered").length || 0,
        refillBase:
          jugs?.filter((j) => j.operational_state === "refill_base").length ||
          0,
        forProduction:
          jugs?.filter((j) =>
            [
              "ready_for_production",
              "in_washing",
              "in_filling",
              "ready_for_delivery",
            ].includes(j.operational_state)
          ).length || 0,
        discarded:
          jugs?.filter((j) => j.physical_state === "discarded").length || 0,
      };

      setInventorySummary(summary);
      return summary;
    } catch (error) {
      setError(error.message);
      return {
        total: 0,
        inInventory: 0,
        delivered: 0,
        refillBase: 0,
        forProduction: 0,
        discarded: 0,
      };
    } finally {
      setLoading(false);
    }
  };

  // Método para obtener botellones en estado de alerta
  const getJugsWithAlerts = async () => {
    try {
      setLoading(true);
      setError(null);

      const { data, error } = await supabase
        .from("jugs_alerts_view")
        .select("*")
        .order("priority", { ascending: false });

      if (error) throw error;

      return data || [];
    } catch (error) {
      setError(error.message);
      return [];
    } finally {
      setLoading(false);
    }
  };

  // Método para obtener botellones en base de recambio
  const getRefillBaseJugs = async () => {
    try {
      setLoading(true);
      setError(null);

      const { data, error } = await supabase
        .from("jugs")
        .select("*")
        .eq("operational_state", "refill_base")
        .order("updated_at", { ascending: true });

      if (error) throw error;

      console.log("Botellones en base de recambio:", data);
      return data || [];
    } catch (error) {
      setError(error.message);
      console.error("Error obteniendo botellones de recambio:", error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  // Método para obtener el historial de un botellón
  const getJugHistory = async (jugId) => {
    try {
      setLoading(true);
      setError(null);

      const { data, error } = await supabase
        .from("jug_state_history")
        .select(
          `
          id,
          jug_id,
          previous_physical_state,
          new_physical_state,
          previous_operational_state,
          new_operational_state,
          change_date,
          notes,
          user_id
        `
        )
        .eq("jug_id", jugId)
        .order("change_date", { ascending: false });

      if (error) throw error;

      return data || [];
    } catch (error) {
      setError(error.message);
      return [];
    } finally {
      setLoading(false);
    }
  };

  // Método para reservar botellones para base de recambio
  const reserveJugsForRefillBase = async (quantity) => {
    try {
      setLoading(true);
      setError(null);

      // Primero obtenemos los botellones disponibles en inventario
      const { data: availableJugs, error: availableError } = await supabase
        .from("jugs")
        .select("id")
        .eq("operational_state", "in_inventory")
        .limit(quantity);

      if (availableError) throw availableError;

      if (availableJugs.length < quantity) {
        throw new Error(
          `Solo hay ${availableJugs.length} botellones disponibles para reservar`
        );
      }

      // Actualizamos el estado de los botellones seleccionados
      const jugIds = availableJugs.map((jug) => jug.id);

      const { data, error } = await supabase
        .from("jugs")
        .update({ operational_state: "refill_base" })
        .in("id", jugIds)
        .select();

      if (error) throw error;

      // Actualizamos la lista de botellones y el resumen
      await getAllJugs();
      await getInventorySummary();

      return data;
    } catch (error) {
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // Método para obtener productos (botellones)
  const getProducts = async () => {
    try {
      setLoading(true);
      setError(null);

      const { data, error } = await supabase
        .from("products")
        .select("id, name, sku_code, unit_price")
        .eq("is_jug", true) // Solo productos tipo botellón
        .order("name");

      if (error) throw error;

      return data || [];
    } catch (error) {
      setError(error.message);
      return [];
    } finally {
      setLoading(false);
    }
  };

  return (
    <InventoryContext.Provider
      value={{
        loading,
        setLoading,
        error,
        jugsData,
        inventorySummary,
        getAllJugs,
        getJugById,
        createJug,
        updateJug,
        getInventorySummary,
        getJugsWithAlerts,
        getRefillBaseJugs,
        getJugHistory,
        reserveJugsForRefillBase,
        getProducts,
      }}
    >
      {children}
    </InventoryContext.Provider>
  );
};
