import React, { useState, useEffect } from "react";
import { X } from "lucide-react";

const FloatingWidget = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [opacity, setOpacity] = useState(0);
  const rotationSpeed = 80;
  const fadeInDuration = 1500; // 3 seconds

  const rotationKeyframes = `
    @keyframes spin-reverse {
      from {
        transform: rotate(360deg);
      }
      to {
        transform: rotate(0deg);
      }
    }
  `;

  const rotatingImage =
    "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/hablemos-por-whatsapp-item-01-plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad.svg";
  const staticImage =
    "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/hablemos-por-whatsapp-item-02-plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad.png";

  const phoneNumber = "1234567890"; // Replace with your actual WhatsApp number
  const message = "Hola, me gustaría obtener más información sobre los servicios de Fluenti"; // Replace with your desired message

  useEffect(() => {
    setIsVisible(true);
    const fadeInInterval = setInterval(() => {
      setOpacity((prevOpacity) => {
        if (prevOpacity >= 1) {
          clearInterval(fadeInInterval);
          return 1;
        }
        return prevOpacity + 0.01;
      });
    }, fadeInDuration / 100);

    return () => clearInterval(fadeInInterval);
  }, []);

  const handleWhatsAppClick = () => {
    const whatsappUrl = `https://wa.me/${573161232337}?text=${encodeURIComponent(
      message
    )}`;
    window.open(whatsappUrl, "_blank");
  };

  if (!isVisible) return null;

  return (
    <>
      <style>{rotationKeyframes}</style>
      <div
        className="fixed bottom-4 right-4 w-[100px] h-[100px] md:w-[160px] md:h-[160px] flex items-center justify-center cursor-pointer z-50"
        style={{ opacity, transition: `opacity ${fadeInDuration}ms ease-in` }}
        onClick={handleWhatsAppClick}
      >
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsVisible(false);
          }}
          className="absolute -top-2 -right-2 z-20 bg-transparent border-0 rounded-full hover:bg-gray-100 transition-colors duration-200"
        >
          <X size={16} />
        </button>
        <div
          className="absolute w-full h-full rounded-full"
          style={{
            backgroundImage: `url(${rotatingImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            animation: `spin-reverse ${rotationSpeed}s linear infinite`,
          }}
        />
        <div
          className="absolute w-[60%] h-[60%] rounded-full z-10"
          style={{
            backgroundImage: `url(${staticImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </div>
    </>
  );
};

export default FloatingWidget;
