import React, { useState, useEffect } from "react";
import { useSalesContext } from "../../../contexts/SalesContext";
import Stepper from "../../ui/Stepper";

const useBreakpoint = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkBreakpoint = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkBreakpoint();
    window.addEventListener("resize", checkBreakpoint);
    return () => window.removeEventListener("resize", checkBreakpoint);
  }, []);

  return isMobile;
};

const RefillCard = () => {
  const { selectedRefills, setSelectedRefills } = useSalesContext();
  const isMobile = useBreakpoint();

  const commonContent = {
    image: "/assets/img/landing/cart-refill-drops-blue-plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad.png",
    title: "Recambio de Botellón",
    price: "$14.990",
    pricePerLiter: "$749.5 x lt",
  };

  const PreOrderBadge = () => (
    <div className="w-fit px-1 py-0.5 bg-green-200 rounded-md justify-center items-center inline-flex">
      <div className="text-lime-600 text-xs font-medium leading-none">
        Pre-compra
      </div>
    </div>
  );

  const FreeDeliveryBadge = () => (
    <div className="w-fit px-1 py-0.5 bg-gray-200 rounded-md justify-center items-center inline-flex">
      <div className="text-neutral-800 text-xs font-medium leading-none">
        Entrega gratis
      </div>
    </div>
  );

  const BadgesContainer = ({ className = "" }) => (
    <div className={`flex gap-1 ${className}`}>
      <PreOrderBadge />
      <FreeDeliveryBadge />
    </div>
  );

  const StepperComponent = () => (
    <div className="w-28">
      <Stepper
        value={selectedRefills}
        onChange={setSelectedRefills}
        min={0}
        max={20}
      />
    </div>
  );

  const PriceDisplay = ({ className = "" }) => (
    <div className={`flex-col justify-start items-start ${className}`}>
      <div className="text-gray-800 font-medium">
        {commonContent.price}
      </div>
      <div className="text-neutral-600 text-sm font-normal leading-none">
        {commonContent.pricePerLiter}
      </div>
    </div>
  );


  const MobileLayout = () => (
    <div className="p-2 bg-neutral-50 rounded-3xl flex flex-col gap-4">
      <div className="flex gap-2">
        <img
          className="w-24 h-24 object-contain shrink-0"
          src={commonContent.image}
          alt={commonContent.title}
        />

        <div className="flex flex-col gap-2 min-w-0">
          <div className="text-gray-800 text-base font-medium leading-none">
            {commonContent.title}
          </div>
          <BadgesContainer />
          <PriceDisplay className="gap-2" />
          <StepperComponent />
        </div>
      </div>
    </div>
  );

  const DesktopLayout = () => (
    <div className="p-4 bg-neutral-50 rounded-3xl justify-start items-start gap-6 flex">
      <div className="w-1/3 max-h-full overflow-hidden">
        <img
          className="w-full h-full object-cover"
          src={commonContent.image}
          alt={commonContent.title}
        />
      </div>

      <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
        <div className="flex-col justify-start items-start gap-1 flex">
          <div className="text-gray-800 text-xl font-medium leading-normal">
            {commonContent.title}
          </div>
          <BadgesContainer />
        </div>

        <div className="flex-col justify-start items-start gap-6 flex">
          <PriceDisplay className="gap-0.5" />
          <StepperComponent />
        </div>
      </div>
    </div>
  );

  return (
    <div className="pt-4">
      {isMobile ? <MobileLayout /> : <DesktopLayout />}
    </div>
  );
};

export default RefillCard;  