import React, { useEffect, useState } from "react";
import {
  X,
  Home,
  Info,
  Mail,
  Settings,
  ShoppingCart,
  HelpCircle,
  FileText,
} from "lucide-react";
import Coverage from "../pages/Coverage";
import QuickCart from "../store/QuickCart";

const Sidepanel = ({ isOpen, onClose, type = "default" }) => {
  const [isRendered, setIsRendered] = useState(false);
  const [isAnimatingIn, setIsAnimatingIn] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsRendered(true);
      setTimeout(() => setIsAnimatingIn(true), 50);
      document.body.style.overflow = "hidden";
    } else {
      setIsAnimatingIn(false);
      document.body.style.overflow = "visible";
    }

    return () => {
      document.body.style.overflow = "visible";
    };
  }, [isOpen]);

  const handleTransitionEnd = () => {
    if (!isOpen) {
      setIsRendered(false);
    }
  };

  if (!isRendered) return null;

  const panelClasses = `sidepanel min-w-[375px] max-w-[375px] sm:min-w-[420px] sm:max-w-[420px] lg:min-w-[720px] lg:max-w-[720px] flex flex-col flex ${
    isAnimatingIn ? "sidepanel-open" : ""
  } ${type === "coverage" ? "w-[90vw]" : ""}`;

  return (
    <>
      <div className={panelClasses} onTransitionEnd={handleTransitionEnd}>
        <div className="flex p-6 justify-between sticky top-0 bg-white z-10 shadow-sm">
          <div className="text-2xl font-medium flex items-center ga">
            {type === "coverage"
              ? "Zonas de cobertura"
              : type === "quickCart"
              ? "Realizar nuevo pedido"
              : "Opciones "}
          </div>
          <button
            onClick={onClose}
            className="z-50 text-gray-800 bg-transparent border-0 cursor-pointer"
          >
            <span className="sr-only">Close sidebar</span>
            <X className="block h-6 w-6" aria-hidden="true" />
          </button>
        </div>

        {type === "coverage" ? (
          <div className="w-full flex-grow flex flex-col min-w-[375px]">
            <CoverageContent />
          </div>
        ) : type === "quickCart" ? (
          <QuickCartContent />
        ) : (
          <div className="w-full flex-grow flex flex-col min-w-[375px]">
            <DefaultContent type={type} />
          </div>
        )}
      </div>
      <div
        className={`sidepanel-overlay ${
          isAnimatingIn ? "sidepanel-overlay-open" : ""
        }`}
        onClick={onClose}
      ></div>
    </>
  );
};

// Componente para el contenido predeterminado
const DefaultContent = ({ type }) => {
  const linkTypes = {
    default: [
      { name: "Botellones y recambios", icon: ShoppingCart, href: "/" },
      { name: "¿Cómo funciona?", icon: HelpCircle, href: "#" },
    ],
    login: [
      { name: "Home", icon: Home, href: "#" },
      { name: "Pide tu botellón", icon: ShoppingCart, href: "/" },
      { name: "Contact", icon: Mail, href: "#" },
      { name: "Settings", icon: Settings, href: "#" },
    ],
    admin: [
      { name: "Dashboard", icon: Home, href: "#" },
      { name: "Manage Products", icon: ShoppingCart, href: "#" },
      { name: "User Management", icon: Settings, href: "#" },
    ],
  };

  const links = linkTypes[type] || linkTypes.default;

  return (
    <nav className="">
      <ul className="space-y-4">
        {links.map((link) => (
          <li key={link.name}>
            <a
              href={link.href}
              className="flex items-center space-x-2 hover:text-gray-300"
            >
              <link.icon className="h-5 w-5" />
              <span>{link.name}</span>
            </a>
          </li>
        ))}
      </ul>
    </nav>
  );
};

// Componente para el contenido de cobertura
const CoverageContent = () => {
  return (
    <div className="h-full w-full">
      <Coverage />
    </div>
  );
};

const QuickCartContent = () => {
  return (
    <div className="px-8">
      <QuickCart />;
    </div>
  );
};

export default Sidepanel;
