// BackgroundSection.jsx
export const BackgroundSection = () => (
  <div
    className="w-full lg:w-1/2 relative order-2 lg:order-1 flex flex-col items-start justify-end px-6 md:p-8 py-6  text-white"
    style={{
      background: `linear-gradient(rgba(0, 0, 0, 0.12), rgba(0, 0, 0, 0.50)), url('/assets/img/landing/drops-blue-plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad.jpg') center center/cover`,
    }}
  >

    <div className="max-w-[80%]">
      <h1 className="text-4xl lg:text-5xl font-medium mb-4">
        Hidratación que no se detiene
      </h1>
      <p className="text-lg lg:text-xl">
        Innovamos constantemente en como mantenerte hidratado.
      </p>
      <div className="mt-auto py-8">
            <div className="flex justify-between text-sm text-gray-600">
              <a href="/terminos" className="hover:text-gray-900">
                Términos
              </a>
              <a href="/privacidad" className="hover:text-gray-900">
                Privacidad
              </a>
            </div>
          </div>
    </div>
    
  </div>
);
