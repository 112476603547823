import React, { useState, useMemo } from "react";
import { Button } from "@radix-ui/themes";
import { formatPrice, isValidEmail } from "../../../scripts/generalScript";
import { useSalesContext } from "../../../contexts/SalesContext";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const TotalBar = ({ currentStep, onContinue, buttonText }) => {
  const {
    name,
    phone,
    email,
    selectedAddress,
    addressDetails,
    selectedSugs,
    selectedRefills,
    newOrderTotal,
    newSalesTerms,
    addressHasDetails,
  } = useSalesContext();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [stepsCompleted, setStepsCompleted] = useState({
    step1: false,
    step2: false,
    step3: false,
  });

  // Validación específica por paso
  const getStepValidation = () => {
    switch (currentStep) {
      case 1:
        return selectedSugs > 0;
      case 2:
        return true; // Siempre puede continuar en paso 2
      case 3:
        return (
          name.length > 3 &&
          phone.length === 16 &&
          isValidEmail(email) &&
          selectedAddress !== "" &&
          (!addressHasDetails ||
            (addressHasDetails && addressDetails.trim().length > 2))
        );
      case 4:
        return newSalesTerms === true;
      default:
        return false;
    }
  };

  const isStepValid = getStepValidation();

  // Calculate progress percentage
  const progress = useMemo(() => {
    let completedFields = 0;
    const totalFields = 10; // Actualizamos a 10 campos totales (incluye el nuevo paso)

    // Steps progress
    if (stepsCompleted.step1) completedFields++;
    if (stepsCompleted.step2) completedFields++;
    if (stepsCompleted.step3) completedFields++;

    // Original fields
    if (name.length > 3) completedFields++;
    if (phone.length === 16) completedFields++;
    if (isValidEmail(email)) completedFields++;
    if (selectedAddress !== "") completedFields++;
    if (!addressHasDetails || (addressHasDetails && addressDetails.trim().length > 2))
      completedFields++;
    if (selectedSugs > 0 || selectedRefills > 0) completedFields++;
    if (newSalesTerms) completedFields++;

    return (completedFields / totalFields) * 100;
  }, [
    name,
    phone,
    email,
    selectedAddress,
    addressDetails,
    selectedSugs,
    selectedRefills,
    newSalesTerms,
    addressHasDetails,
    stepsCompleted,
  ]);

  const handleAction = (event) => {
    event.preventDefault();

    if (currentStep < 4) {
      if (currentStep === 1) {
        if (!isStepValid) {
          toast.error("Por favor, selecciona al menos un botellón", {
            duration: 3000,
            position: "top-center",
          });
          return;
        }
        setStepsCompleted((prev) => ({ ...prev, step1: true }));
      } else if (currentStep === 2) {
        setStepsCompleted((prev) => ({ ...prev, step2: true }));
      } else if (currentStep === 3) {
        if (!isStepValid) {
          let missingFields = [];
          if (name.length <= 3) missingFields.push("Nombre y apellido");
          if (phone.length !== 16) missingFields.push("Número de WhatsApp");
          if (!isValidEmail(email)) missingFields.push("Correo electrónico");
          if (selectedAddress === "") missingFields.push("Dirección");
          if (addressHasDetails && addressDetails.trim().length <= 2)
            missingFields.push("Detalles de la dirección (mínimo 4 caracteres)");

          toast.error(
            `Por favor, completa los siguientes campos: ${missingFields.join(", ")}`,
            {
              duration: 4000,
              position: "top-center",
            }
          );
          return;
        }
        setStepsCompleted((prev) => ({ ...prev, step3: true }));
      }
      onContinue();
      return;
    }

    // Validación final antes de enviar (paso 4)
    if (!newSalesTerms) {
      toast.error("Por favor, acepta los términos y condiciones para continuar", {
        duration: 3000,
        position: "top-center",
      });
      return;
    }

    setIsSubmitting(true);
    toast.success("¡Todo listo! Enviando tu pedido...", {
      duration: 3000,
      position: "top-center",
      style: {
        borderRadius: "10px",
        background: "#4CAF50",
        color: "#fff",
      },
    });

    setTimeout(() => {
      navigate("/pedido-realizado");
    }, 500);
  };

  return (
    <motion.div
      initial={{ y: 100 }}
      animate={{ y: 0 }}
      exit={{ y: 100 }}
      transition={{ type: "spring", stiffness: 300, damping: 30 }}
      className="fixed bottom-0 left-0 right-0 bg-white flex flex-col justify-between pb-8 shadow-[0_-4px_6px_-1px_rgba(0,0,0,0.1)] z-[9999]"
    >
      {/* Progress bar */}
      <div className="w-full h-1 bg-blue-50 mb-4">
        <motion.div
          className={`h-full ${progress === 100 ? "bg-green-500" : "bg-gray-800"}`}
          initial={{ width: 0 }}
          animate={{ width: `${progress}%` }}
          transition={{ duration: 0.3 }}
        />
      </div>

      {/* Content container */}
      <div className="flex justify-between px-6 items-center h-16">
        <div className="flex flex-col gap-0 relative overflow-hidden">
          <p className="text-sm font-normal p-0 m-0">Subtotal</p>
          <AnimatePresence mode="wait">
            <motion.p
              key={newOrderTotal}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.2 }}
              className="text-2xl p-0 m-0 font-medium"
            >
              ${formatPrice(newOrderTotal)}
            </motion.p>
          </AnimatePresence>
        </div>
        <motion.div whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.95 }}>
          <Button
            className={`text-white text-lg px-4 ${
              isStepValid && !isSubmitting ? "bg-blue-600" : "bg-gray-300"
            }`}
            size={"3"}
            onClick={handleAction}
            disabled={!isStepValid || isSubmitting}
          >
            {buttonText || (isSubmitting ? "Enviando..." : currentStep === 4 ? "Enviar pedido" : "Continuar")}
          </Button>
        </motion.div>
        <Toaster />
      </div>
    </motion.div>
  );
};

export default TotalBar;