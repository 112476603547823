import React, { useState } from "react";
import { Eye, EyeOff, AlertCircle } from "lucide-react";

const InputField = ({
  value,
  onChange,
  placeholder,
  type = "text",
  isPassword = false,
  name,
  error,
  className = "",
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={`relative ${className}`}>
      <div
        className={`px-6 py-3 bg-stone-50 rounded-3xl flex items-center justify-between group transition-colors ${
          error ? "ring-2 ring-red-500 ring-opacity-50" : ""
        }`}
      >
        <input
          type={isPassword ? (showPassword ? "text" : "password") : type}
          value={value}
          onChange={onChange}
          name={name}
          autoComplete={name}
          className="w-full bg-transparent text-md font-normal font-['Fredoka'] leading-normal outline-none placeholder:text-stone-400
          [-webkit-appearance:none]
          [appearance:none]
          [background-clip:padding-box]
          [-webkit-background-clip:padding-box]
          [-webkit-box-shadow:0_0_0_1000px_rgb(250_250_249)_inset]
          [-webkit-text-fill-color:inherit]"
          placeholder={placeholder}
        />

        {isPassword && (
          <button
            type="button"
            onClick={() => setShowPassword(!showPassword)}
            className="p-0.5 flex bg-stone-50 items-center justify-center ml-2 border-none background-none"
          >

            {showPassword ? (
              <EyeOff className="w-6 h-6 border-none" />
            ) : (
              <Eye className="w-6 h-6 border-none" />
            )}

          </button>
        )}
      </div>
      {error && (
        <div className="flex items-center gap-1 mt-1 text-red-500 text-sm">
          <AlertCircle className="w-4 h-4" />
          <span>{error}</span>
        </div>
      )}
    </div>
  );
};

export default InputField;
