import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useSupaContext } from "../contexts/SupaContext";

const PrivateRoute = ({ children }) => {
  const { user, authInitialized } = useSupaContext();
  const [renderState, setRenderState] = useState("pending");
  
  useEffect(() => {
    // Only make access decision once auth is initialized
    if (!authInitialized) return;
    
    // Set the render state based on user authentication
    setRenderState(user !== null ? "authorized" : "unauthorized");
  }, [user, authInitialized]);

  // While checking authentication, render nothing
  if (renderState === "pending") {
    return null;
  }
  
  // Once decided, either render children or redirect
  return renderState === "authorized" ? children : <Navigate to="/ingresar" replace />;
};

export default PrivateRoute;