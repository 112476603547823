import React, { useState, useEffect } from "react";
import "./App.css";
import Index from "./componets/landingPage/Index";
import { SupaProvider } from "./contexts/SupaContext";
import { SalesProvider } from "./contexts/SalesContext";
import { InventoryProvider } from "./contexts/InventoryContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./componets/pages/Login";
import Dashboard from "./componets/dashboard/Dashboard";
import { Theme } from "@radix-ui/themes";
import PageNotFound from "./componets/navigation/PageNotFound";
import RentSug from "./componets/store/RentSug";
import SuccessOrder from "./componets/store/SuccessOrder";
import ComingSoon from "./componets/pages/ComingSoon";
import AccessPage from "./componets/pages/AccessPage";
import PolicyTabs from "./componets/pages/PolicyTabs";
import PrivateRoute from "./routers/PrivateRoute";

function App() {
  const [isWebView, setIsWebView] = useState(false);
  // Estado para controlar si mostrar Coming Soon o la app
  const isComingSoon = false; // Cambia esto a false para mostrar la app completa

  useEffect(() => {
    const checkWebView = () => {
      const isStandalone = window.matchMedia(
        "(display-mode: standalone)"
      ).matches;
      const isIOSStandalone = window.navigator.standalone;
      setIsWebView(isStandalone || isIOSStandalone);
    };
    checkWebView();
  }, []);

  // Si isComingSoon es true, mostramos la página de Coming Soon
  if (isComingSoon) {
    return <ComingSoon />;
  }

  // Si no, mostramos la aplicación normal
  return (
    <Theme panelBackground="solid" radius="large">
      <BrowserRouter>
        <SupaProvider>
          <InventoryProvider>
            <SalesProvider>
              <Routes>
                <Route path="/" element={<Index />} />
                <Route path="/pedido-realizado" element={<SuccessOrder />} />
                <Route path="/ingresar" element={<AccessPage />} />
                <Route path="/nuevo-pedido" element={<RentSug />} />
                <Route
                  path="/app"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/app/*"
                  element={
                    <PrivateRoute>
                      <Dashboard />
                    </PrivateRoute>
                  }
                />
                <Route path="*" element={<PageNotFound />} />
                <Route
                  path="/privacidad"
                  element={<PolicyTabs activeTab="privacy" />}
                />
                <Route
                  path="/datos"
                  element={<PolicyTabs activeTab="data" />}
                />
                <Route
                  path="/terminos"
                  element={<PolicyTabs activeTab="terms" />}
                />
                <Route
                  path="/manual"
                  element={<PolicyTabs activeTab="manual" />}
                />
                <Route
                  path="/docs"
                  element={<PolicyTabs activeTab="terms" />}
                />
              </Routes>
            </SalesProvider>
          </InventoryProvider>
        </SupaProvider>
      </BrowserRouter>
    </Theme>
  );
}

export default App;
