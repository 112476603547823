import React, { useState, useEffect } from "react";
import * as Tabs from "@radix-ui/react-tabs";
import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";
import SignInForm from "./accessComponents/SignInForm";
import SignUpForm from "./accessComponents/SignUpForm";
import TabNavigation from "./accessComponents/TabNavigation";
import { useSupaContext } from "../../contexts/SupaContext";
import { BackgroundSection } from "./accessComponents/BackgroundSection";

const AccessPage = ({ initialTab = "signin", defaultTab = "signin" }) => {
  const navigate = useNavigate();
  const { login, signup, user } = useSupaContext();
  const [activeTab, setActiveTab] = useState(initialTab || defaultTab);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [signInData, setSignInData] = useState({
    email: "",
    password: "",
  });
  const [signUpData, setSignUpData] = useState({
    first_name: "",
    middle_name: "",
    last_name: "",
    second_last_name: "",
    email: "",
    password: "",
  });
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    if (user) {
      navigate("/app");
    }
  }, [user, navigate]);

  // Efecto para actualizar el tab activo cuando cambia initialTab
  useEffect(() => {
    if (initialTab) {
      setActiveTab(initialTab);
    }
  }, [initialTab]);

  const validatePassword = (password) => {
    const hasMinLength = password.length >= 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);
    return hasMinLength && hasUpperCase && hasNumber;
  };

  const handleSignInChange = (field) => (e) => {
    setSignInData((prev) => ({
      ...prev,
      [field]: e.target.value,
    }));
  };

  const handleSignUpChange = (field) => (e) => {
    const value = e.target.value;
    setSignUpData((prev) => ({
      ...prev,
      [field]: value,
    }));

    if (field === "password") {
      if (!value) {
        setPasswordError("");
      } else if (!validatePassword(value)) {
        setPasswordError(
          "Usa mínimo 8 caracteres, y al menos una mayúscula y un número"
        );
      } else {
        setPasswordError("");
      }
    }
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      await login(signInData.email, signInData.password);
      navigate("/app");
    } catch (error) {
      setError("Correo o contraseña incorrectos");
      console.error("Error de inicio de sesión:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      await signup({
        email: signUpData.email,
        password: signUpData.password,
        first_name: signUpData.first_name,
        middle_name: signUpData.middle_name,
        last_name: signUpData.last_name,
        second_last_name: signUpData.second_last_name,
      });
      navigate("/app");
    } catch (error) {
      setError("Error al crear la cuenta. Por favor intenta nuevamente.");
      console.error("Error de registro:", error);
    } finally {
      setLoading(false);
    }
  };

  if (user) {
    return null;
  }

  return (
    <div className="flex flex-col lg:flex-row min-h-screen max-h-screen">
      <div className="lg:w-1/2 flex pt-16 px-8 md:px-24 justify-center order-1 lg:order-2">
        <div className="w-full flex min-h-[80vh] flex-col gap-8 ">
          <div className="text-center flex flex-col items-center ">
            <img
              src="/assets/logos/fluenti-logo-black-140.svg"
              alt="logo"
              className="h-20"
            />
            <p className="text-gray-600 text-lg p-0 m-0">
              Ingresa o regístrate para continuar
            </p>
          <div className="w-full flex justify-center items-center mt-4">
            <TabNavigation activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
          </div>




          <Tabs.Root value={activeTab} onValueChange={setActiveTab}>
            <AnimatePresence>
              <Tabs.Content value="signin">
                <SignInForm
                  signInData={signInData}
                  handleChange={handleSignInChange}
                  handleSubmit={handleSignIn}
                  loading={loading}
                />
              </Tabs.Content>

              <Tabs.Content value="signup">
                <SignUpForm
                  signUpData={signUpData}
                  handleChange={handleSignUpChange}
                  handleSubmit={handleSignUp}
                  loading={loading}
                  passwordError={passwordError}
                  validatePassword={validatePassword}
                />
              </Tabs.Content>
            </AnimatePresence>
          </Tabs.Root>

          {error && (
            <div className="mt-8 p-3 bg-red-100 text-red-700 rounded-lg">
              {error}
            </div>
          )}


        </div>
      </div>
      <BackgroundSection />
    </div>
  );
};

export default AccessPage;
