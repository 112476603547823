import React, { useState, useEffect } from "react";
import { useSalesContext } from "../../../contexts/SalesContext";

const useBreakpoint = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkBreakpoint = () => {
      setIsMobile(window.innerWidth < 768);
    };

    checkBreakpoint();
    window.addEventListener("resize", checkBreakpoint);
    return () => window.removeEventListener("resize", checkBreakpoint);
  }, []);

  return isMobile;
};

const DispenserCard = () => {
  const { selectedSugs } = useSalesContext();
  const isMobile = useBreakpoint();

  const commonContent = {
    image: "/assets/img/landing/cart-dispenser-drops-blue-plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad.png",
    title: "Dispensador Recargable",
    price: "$0",
    priceBefore: "$12.990",
    description: {
      bold: "Oferta limitada, ",
      regular: "uno por botellón, máx. 3 por cliente."
    }

  };

  const FreeBadge = () => (
    <div className="w-fit px-1 py-0.5 bg-fuchsia-100 rounded-md justify-center items-center inline-flex">
      <div className="text-fuchsia-600 text-xs font-medium leading-none">
        Gratis
      </div>
    </div>
  );

  const DeliveryBadge = () => (
    <div className="w-fit px-1 py-0.5 bg-gray-200 rounded-md justify-center items-center inline-flex">
      <div className="text-neutral-800 text-xs font-medium leading-none">
        Incluido con botellón
      </div>
    </div>
  );

  const BadgesContainer = ({ className = "" }) => (
    <div className={`flex gap-1 ${className}`}>
      <FreeBadge />
      <DeliveryBadge />
    </div>
  );

  const QuantityDisplay = ({ className = "" }) => (
    <div className="flex h-full justify-between items-end">
        <div className={`py-1.5 max-w-24 max-h-8 bg-blue-100 rounded-md justify-center items-center inline-flex ${className}`}>
          <div className="px-4 justify-center items-center flex">
            <div className="text-blue-600 text-xl font-medium leading-7">
              {selectedSugs > 3 ? "3" : selectedSugs}{ selectedSugs > 1 ? " uds" : " ud"}
            </div>
          </div>
        </div>

    </div>
  );

  const DescriptionBox = ({ className = "" }) => (
    <div className={`bg-zinc-100 rounded-2xl ${className}`}>
      <p className="text-neutral-600 text-sm font-normal leading-none">
        <span className="font-medium">{commonContent.description.bold}</span>
        {commonContent.description.regular}
      </p>
    </div>
  );

  const PriceDisplay = ({ className = "" }) => (
    <div className={`flex-col justify-start items-start ${className}`}>
      <div className="self-stretch text-gray-800 text-xl font-medium leading-snug">
        {commonContent.price}
      </div>
      <div className="self-stretch text-neutral-600 text-sm font-normal leading-none line-through">
        {commonContent.priceBefore}
      </div>
    </div>
  );

  const MobileLayout = () => (
    <div className="p-2 bg-neutral-50 rounded-3xl flex flex-col gap-4">
      <div className="flex gap-2">
        <img
          className="w-24 h-24 object-contain shrink-0"
          src={commonContent.image}
          alt={commonContent.title}
        />

        <div className="flex flex-col gap-2 min-w-0">
          <div className="text-gray-800 text-base font-medium leading-none">
            {commonContent.title}
          </div>
          <BadgesContainer />
          <PriceDisplay />
          <QuantityDisplay />
        </div>
      </div>
      <DescriptionBox className="px-2" />
    </div>
  );

  const DesktopLayout = () => (
    <div className="p-4 bg-neutral-50 rounded-3xl justify-start items-start gap-6 flex">
      <div className="w-1/3 max-h-full overflow-hidden">
        <img
          className="w-full h-full object-cover"
          src={commonContent.image}
          alt={commonContent.title}
        />
      </div>

      <div className="grow shrink basis-0 self-stretch flex-col justify-start items-start gap-2 inline-flex">
        <div className="self-stretch flex-col justify-start items-start gap-1 flex">
          <div className="self-stretch text-gray-800 text-xl font-medium leading-0">
            {commonContent.title}
          </div>
          <BadgesContainer />
        </div>

        <div className="flex-col h-full justify-between items-start gap-6 flex">
          <PriceDisplay />
          <QuantityDisplay />
          <DescriptionBox className="px-4" />
        </div>

      </div>
    </div>
  );

  return (
    <div>
      {isMobile ? (
        <div className="pt-4">
          <MobileLayout />
        </div>
      ) : (
        <div className="pt-4">
          <DesktopLayout />
        </div>
      )}
    </div>
  );
};

export default DispenserCard;