import React, { useState, useEffect } from "react";
import NumbersSelect from "../ui/NumbersSelect";
import Sidepanel from "../navigation/Sidepanel";
import { useSalesContext } from "../../contexts/SalesContext";

const Hero = () => {
  // State management
  const [isMobile, setIsMobile] = useState(false);
  const [bottleCount, setBottleCount] = useState(1);
  const [activeSidebar, setActiveSidebar] = useState(null);
  const { selectedSugs, setSelectedSugs } = useSalesContext();

  // Effects
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Handlers
  const closeSidebar = () => {
    setActiveSidebar(null);
  };

  // Styles
  const desktopHeroStyles = {
    backgroundImage: `url("")`,
    backgroundColor: "#1e40af",
    backgroundSize: "cover",
    backgroundPosition: "bottom",
    backgroundRepeat: "no-repeat",
  };

  const mobileHeroStyles = {
    backgroundColor: "#1e40af",
    backgroundImage: `url("/assets/img/mobile-background.jpg")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  return (
    <div className="flex flex-col relative overflow-hidden" id="hero">
      {isMobile ? (
        // Mobile Layout
        <div className="pt-32 md:pt-40 px-24 pb-36 bg-blue-800 flex items-center">
          <div className="w-full relative z-40">
            <div className="flex flex-col gap-8">
              <h1 className="text-white text-5xl font-normal p-0 m-0 text-center tracking-wider leading-12 uppercase">
              Tu solución integral en
              <span className="font-medium">{" Agua Purificada"} </span>
              </h1>

              <h3 className="text-2xl p-0 m-0 font-light text-center leading-8 text-white">
                Pide con nosotros
                <span className="font-medium">
                  {" botellones, y recambios "}
                </span>
                con domicilio gratis!
              </h3>

              <div className="p-4 bg-white rounded-2xl flex-col gap-4">
                <NumbersSelect
                  label="¿Cuántos botellones necesitas?"
                  minNumber={1}
                  maxNumber={10}
                  counter={selectedSugs}
                  setCounter={setSelectedSugs}
                />
                <button
                  className="h-10 w-full mt-4 px-6 bg-blue-600 text-white rounded-md font-medium text-lg  font-['Fredoka'] border-none"
                  onClick={() => setActiveSidebar(true)}
                >
                  {`Pedir ${selectedSugs > 1 ? "Botellones" : "Botellón"}`}
                </button>
              </div>
            </div>
          </div>
          <img
            src="/assets/img/landing/filling-mobile-plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad.jpg"
            className="absolute top-0 right-0 h-full w-auto z-10"
            alt=""
          />
          <img
            src="/assets/img/landing/curvedborderwhite.svg"
            className="w-full absolute bottom-[-1px] right-0 z-20 mt-2"
            alt="curved border"
          />
        </div>
      ) : (
        // Desktop Layout
        <div
          className="w-screen flex items-start justify-center py-32"
          style={desktopHeroStyles}
        >
          <div className="flex flex-col items-start gap-4 container px-4">
            <h1 className="text-6xl font-normal p-0 m-0 w-full md:max-w-[64%] z-20 text-white tracking-wider leading-12 uppercase">
              Tu solución integral en
              <span className="font-medium">{" Agua Purificada"} </span>
            </h1>

            <h3 className="text-2xl font-light w-full md:max-w-[75%] text-white leading-loose z-20">
              Pide aquí
              <span className="font-medium">{" botellones y recambios "}</span>
              con domicilio gratis!
            </h3>

            <div className="bg-white p-4 rounded-2xl z-20">
              <div className="flex items-end justify-between gap-8">
                <NumbersSelect
                  label="¿Cuántos botellones necesitas?"
                  minNumber={1}
                  maxNumber={20}
                  counter={selectedSugs}
                  setCounter={setSelectedSugs}
                />
                <button
                  className="h-10 w-48 px-6 bg-blue-600 text-white rounded-md font-medium text-lg border-none  font-['Fredoka']"
                  onClick={() => setActiveSidebar(true)}
                >
                  {`Pedir ${selectedSugs > 1 ? "Botellones" : "Botellón"}`}
                </button>
              </div>
            </div>
          </div>
          <img
            src="/assets/img/landing/filling-plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad.jpg"
            className="absolute top-0 right-0 h-full w-auto z-10"
            alt=""
          />
          <img
            src="/assets/img/landing/curvedborderwhite.svg"
            className="w-screen absolute bottom-[-1px] z-20 mt-2"
            alt="curved border"
          />
        </div>
      )}
      <Sidepanel
        isOpen={!!activeSidebar}
        onClose={closeSidebar}
        type={"quickCart"}
      />
    </div>
  );
};

export default Hero;
