import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const WaterService = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [deliveryMessage, setDeliveryMessage] = useState("");

  // This would typically come from your app's configuration or API
  const isExpressDeliveryEnabled = false;

  const calculateDeliveryMessage = () => {
    const now = new Date();
    const colombiaTime = new Intl.DateTimeFormat("es-CO", {
      timeZone: "America/Bogota",
      hour: "numeric",
      hour12: false,
    }).format(now);

    const currentHour = parseInt(colombiaTime);

    // Express delivery logic
    if (isExpressDeliveryEnabled && currentHour >= 9 && currentHour < 16) {
      return "Pide ahora y recibe en menos de 90 minutos";
    }

    // Regular delivery logic
    if (currentHour >= 0 && currentHour <= 10) {
      return "Pide ahora y recibe por la tarde";
    } else if (currentHour > 10 && currentHour <= 16) {
      return "Pide ahora y recibe mañana por la mañana";
    } else {
      return "Pide ahora y recibe mañana por la tarde";
    }
  };

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);

    // Set initial delivery message
    setDeliveryMessage(calculateDeliveryMessage());

    // Update delivery message every minute
    const messageInterval = setInterval(() => {
      setDeliveryMessage(calculateDeliveryMessage());
    }, 60000);

    return () => {
      window.removeEventListener("resize", checkIfMobile);
      clearInterval(messageInterval);
    };
  }, []);

  const features = [
    {
      title: "19.5 lts de Agua Purificada",
      description: "Gran calidad y sabor refrescante.",
    },
    {
      title: "Purificación en 6 etapas",
      description: "Filtros especializados, Osmosis Inversa y UV.",
    },
    {
      title: "Botellón incluido en con el recambio.",
      description: "Te lo prestamos (no necesitas comprarlo).",
    },
  ];

  const benefits = [
    {
      title: "Recámbialo cuando se acabe",
      description: "Pide uno lleno y entrega el vacio. Por el mismo precio.",
    },
    {
      title: "Retornarlo cuando no lo uses ♻",
      description: "Cuando dures mas de 30 sin recambios nosotros lo buscamos.",
    },
    {
      title: "Domicilio programado gratis",
      description: "Lo entregamos donde lo necesites.",
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3,
      },
    },
  };

  const itemVariants = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: "easeOut",
      },
    },
  };

  const imageVariants = {
    hidden: {
      opacity: 0,
      scale: 0.8,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.8,
        ease: "easeOut",
      },
    },
  };

  const buttonVariants = {
    initial: { scale: 1 },
    hover: {
      scale: 1.05,
      transition: {
        duration: 0.2,
      },
    },
    tap: {
      scale: 0.95,
    },
  };

  const ContentBlock = ({ items, alignment = "start" }) => (
    <motion.div
      className="flex-1 flex flex-col justify-center items-start gap-8"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {items.map((item, index) => (
        <motion.div key={index} className="w-full" variants={itemVariants}>
          <div className="w-full bg-white rounded-2xl flex flex-col justify-center items-start gap-2">
            <div
              className={`text-neutral-800 text-xl font-medium font-['Fredoka'] leading-tight ${
                alignment === "end" ? "text-right w-full" : ""
              }`}
            >
              {item.title}
            </div>
            <div
              className={`text-neutral-400 text-base font-normal font-['Fredoka'] leading-none ${
                alignment === "end" ? "text-right w-full" : ""
              }`}
            >
              {item.description}
            </div>
          </div>
          {index < items.length - 1 && (
            <div className="w-full h-px bg-sky-100 mt-6" />
          )}
        </motion.div>
      ))}
    </motion.div>
  );

  const DeliveryInfo = () => (
    <div className="bg-sky-100 justify-start items-center gap-1 inline-flex">
      <div className="p-2 justify-center items-center gap-2.5 flex">
        <div className="text-center text-neutral-800 text-xl font-medium font-['Fredoka'] leading-tight">
          {deliveryMessage}
        </div>
      </div>
    </div>
  );

  return (
    <div
      className={`bg-white flex-col justify-center items-center gap-6 inline-flex w-full`}
    >
      {isMobile ? (
        <div className="w-full min-h-screen">
          <div className="relative flex">
            <div className="w-3/5 p-8">
              <div className="flex flex-col gap-12 md:gap-6">
                <motion.div
                  className="bg-white rounded-2xl flex-col gap-2 flex"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5 }}
                >
                  <div className="bg-sky-100 w-fit p-2 px-4 flex">
                    <div className="text-center text-neutral-800 text-2xl font-medium font-['Fredoka'] leading-tight">
                      Botellón Fluenti
                    </div>
                  </div>
                </motion.div>
                <ContentBlock items={features} />
                <ContentBlock items={benefits} />
              </div>
            </div>

            <div className="w-2/5">
              <div className="sticky top-0 pt-4 w-80">
                <motion.img
                  className="w-full h-auto object-contain"
                  src="/assets/img/landing/full-sug-drops-blue-plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad.png"
                  alt="Botellón de agua"
                  variants={imageVariants}
                  initial="hidden"
                  animate="visible"
                />
              </div>
            </div>
          </div>

          <div className="p-4">
            <motion.div
              className="bg-white rounded-2xl flex-col justify-center items-center gap-2 flex"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
            >
              <div className="text-center text-gray-800 text-2xl font-medium font-['Fredoka'] leading-none">
                Pidelo por $15.990
              </div>
              <DeliveryInfo />
            </motion.div>

            <div className="mt-4 flex justify-center">
              <motion.button
                className="h-10 px-4 py-2 border-none bg-blue-600 rounded-md justify-center items-center inline-flex"
                variants={buttonVariants}
                initial="initial"
                whileHover="hover"
                whileTap="tap"
              >
                <div className="text-center text-white text-lg font-medium font-['Fredoka'] leading-tight">
                  Pedir botellones
                </div>
              </motion.button>
            </div>
          </div>
        </div>
      ) : (
        <>
          <motion.div
            className="bg-white rounded-2xl flex-col justify-center items-center gap-2 flex"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
          >
            <div className="bg-sky-100 justify-start items-center gap-1 inline-flex">
              <div className="p-2 justify-center items-center gap-2.5 flex">
                <div className="text-center text-neutral-800 text-2xl font-medium font-['Fredoka'] leading-tight">
                  Botellón Fluenti
                </div>
              </div>
            </div>
          </motion.div>

          <motion.div
            className="self-stretch justify-center items-center gap-2.5 inline-flex"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
          >
            <ContentBlock items={features} alignment="end" />
            <motion.img
              className="w-72"
              src="/assets/img/landing/full-sug-drops-blue-plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad.png"
              alt="Botellón de agua"
              variants={imageVariants}
              initial="hidden"
              animate="visible"
            />
            <ContentBlock items={benefits} />
          </motion.div>

          <motion.div
            className="bg-white rounded-2xl flex-col justify-center items-center gap-2 flex"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
          >
            <div className="text-center text-gray-800 text-2xl font-medium font-['Fredoka'] leading-none">
              Pidelo por $14.990
            </div>
            <DeliveryInfo />
          </motion.div>

          <motion.button
            className="h-10 px-4 py-2 bg-blue-600 rounded-md justify-center items-center inline-flex border-none hover:bg-blue-700 transition-all duration-300 cursor-pointer"
            variants={buttonVariants}
            initial="initial"
            whileHover="hover"
            whileTap="tap"
          >
            <div className="text-center text-white text-lg font-medium font-['Fredoka'] leading-tight">
              Pedir botellones
            </div>
          </motion.button>
        </>
      )}
    </div>
  );
};

export default WaterService;
