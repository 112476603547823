import React from 'react';

const TabTrigger = ({ value, isActive, onClick, children }) => (
  <button
    type="button"
    role="tab"
    aria-selected={isActive}
    onClick={() => onClick(value)}
    className={`
      relative px-6 py-2 text-lg w-full font-['Fredoka']  transition-all duration-200
      ${isActive 
        ? "text-white bg-gray-800 rounded-full font-medium border-none" 
        : "text-gray-600 bg-stone-50 font-normal rounded-full hover:text-gray-800 border-none bg-transparent"
      }
    `}
  >
    {children}
    {isActive && (
      <div
        className="absolute inset-0 rounded-lg shadow-sm transition-all duration-300"
        style={{ zIndex: -1 }}
      />
    )}
  </button>
);

export const TabNavigation = ({ activeTab, setActiveTab }) => {
  return (
    <div 
      role="tablist" 
      className="mt-4 inline-flex w-[300px] items-center rounded-full bg-stone-50 backdrop-blur-sm"
    >
      <TabTrigger
        value="signin"
        isActive={activeTab === "signin"}
        onClick={setActiveTab}
      >
        Ingresar
      </TabTrigger>
      <TabTrigger
        value="signup"
        isActive={activeTab === "signup"}
        onClick={setActiveTab}
      >
        Registrarme
      </TabTrigger>
    </div>
  );
};

export default TabNavigation;