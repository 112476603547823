import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import DividerComponent from "../ui/DividerComponent";
import BottleSection from "./quick-cart-components/BottleSection";
import RefillSection from "./quick-cart-components/RefillSection";
import DeliverySection from "./quick-cart-components/DeliverySection";
import TotalBar from "./quick-cart-components/TotalBar";
import CheckTermsComponent from "./quick-cart-components/CheckTermsComponent";
import { useSalesContext } from "../../contexts/SalesContext";
import { ChevronLeft, Loader } from "lucide-react";
import WaterBottleCard from "./quick-cart-components/WaterBottleCard";
import DispenserCard from "./quick-cart-components/DispenserCard";
import RefillCard from "./quick-cart-components/RefillCard";

const QuickCart = () => {
  const { selectedAddress, selectedSugs, selectedRefills } = useSalesContext();
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 600);
    return () => clearTimeout(timer);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2,
        delayChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: "spring",
        stiffness: 300,
      },
    },
    exit: {
      opacity: 0,
      x: 20,
      transition: {
        duration: 0.2,
      },
    },
  };

  const loaderVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.8,
      transition: {
        duration: 0.3,
      },
    },
  };

  const handleContinue = () => {
    if (currentStep < 4) {
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const getStepTitle = () => {
    switch (currentStep) {
      case 1:
        return "Botellones";
      case 2:
        return "Recambios adicionales";
      case 3:
        return "Información de entrega";
      case 4:
        return "Resumen del pedido";
      default:
        return "";
    }
  };

  const descriptionContent = {
    refills: {
      number: "2",
      title: "Recambios adicionales",
      description: "Precompra recambios y ahorra tiempo al pedirlos.",
      className: "mt-0 mb-2",
    },
    bottles: {
      number: "1",
      title: "Botellones",
      description: "Selecciona la cantidad de botellones que necesitas.",
      className: "mt-8 mb-2",
    },
    delivery: {
      number: "3",
      title: "Información de entrega",
      description: "Ingresa los datos para la entrega de tu pedido.",
      className: "mt-0 mb-8",
    },
    summary: {
      number: "4",
      title: "Resumen del pedido",
      description: "Revisa tu pedido y acepta los términos y condiciones.",
      className: "mt-0 mb-8",
    },
  };

  const DescriptionBox = ({ className = "", type = "refills" }) => (
    <div className={`flex gap-2 ${descriptionContent[type].className}`}>
      <div className="bg-gray-800 text-gray-50 max-w-10 min-w-10 max-h-10 min-h-10 justify-center items-center rounded-full flex font-light text-2xl">
        {descriptionContent[type].number}
      </div>
      <div className={`rounded-2xl flex flex-col gap-1 ${className}`}>
        <h2 className="text-gray-800 text-xl font-medium leading-none p-0 m-0">
          {descriptionContent[type].title}
        </h2>
        {descriptionContent[type].description ? (
          <p className="text-neutral-500 text-sm font-normal leading-none p-0 m-0">
            {descriptionContent[type].description}
          </p>
        ) : null}
      </div>
    </div>
  );

  const OrderSummary = () => (
    <div className="flex flex-col gap-4">
      <div className="bg-gray-50 p-4 rounded-2xl flex flex-col gap-4">
        <h3 className="text-lg font-medium p-0 m-0 flex flex-col">
          Resumen de tu pedido
          <span className="font-normal text-base break-words">
            {selectedAddress}
          </span>
        </h3>
        <div className="space-y-4">
          <div className="flex justify-between items-center  border-b border-gray-200">
            <span className="text-gray-600">Botellones</span>
            <div className="font-medium text-right flex flex-col ">
              <h3 className="font-medium text-xl p-0 m-0  ">
                ${(selectedSugs * 14990).toLocaleString()}
              </h3>
              <p className="text-sm font-normal p-0 m-0 text-gray-600">
                $14.990 x {selectedSugs} ud.
              </p>
            </div>
          </div>
          <div className="flex justify-between items-center  border-b border-gray-200">
            <span className="text-gray-600">Recambios</span>
            <div className="font-medium text-right flex flex-col ">
              <h3 className="font-medium text-xl p-0 m-0  ">
                ${(selectedRefills * 14990).toLocaleString()}
              </h3>
              <p className="text-sm font-normal p-0 m-0 text-gray-600">
                $14.990 x {selectedRefills} ud.
              </p>
            </div>
          </div>
        </div>
      </div>
      <CheckTermsComponent />
    </div>
  );

  const renderHeader = () => {
    if (currentStep === 1) return null;

    return (
      <motion.div
        className="my-8 flex items-center gap-2 cursor-pointer"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        onClick={handleBack}
      >
        <button className="cursor-pointer rounded-full max-w-8 max-h-8 min-w-8 min-h-8 border-none justify-center flex items-center text-gray-600 hover:text-gray-800 transition-colors">
          <ChevronLeft className="max-w-6 max-h-6 min-w-6 min-h-6" />
        </button>
        Atrás
      </motion.div>
    );
  };

  const renderLoader = () => (
    <motion.div
      className="fixed inset-0 bg-white z-50 flex items-center justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="flex flex-col items-center gap-4"
        variants={loaderVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        <div className="w-16 h-16 border-4 border-blue-200 border-t-blue-500 rounded-full animate-spin"></div>
        <Loader
          className="max-w-8 max-h-8 min-w-8 min-h-8 animate-spin"
          color="#2563EB"
        />
      </motion.div>
    </motion.div>
  );

  const renderStepContent = () => {
    switch (currentStep) {
      case 1:
        return (
          <motion.div
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            key="step1"
            className="flex-grow overflow-y-auto pb-48 relative"
          >
            <DescriptionBox type="bottles" />
            <WaterBottleCard />
            <DispenserCard />
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            key="step2"
            className="flex-grow overflow-y-auto pb-48 relative"
          >
            <DescriptionBox type="refills" />
            <RefillCard />
          </motion.div>
        );
      case 3:
        return (
          <motion.div
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            key="step3"
            className="flex-grow overflow-y-auto pb-48 relative"
          >
            <DescriptionBox type="delivery" />
            <DeliverySection />
          </motion.div>
        );
      case 4:
        return (
          <motion.div
            variants={itemVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
            key="step4"
            className="flex-grow overflow-y-auto pb-48 relative"
          >
            <DescriptionBox type="summary" />
            <OrderSummary />
          </motion.div>
        );
      default:
        return null;
    }
  };

  if (isLoading) {
    return renderLoader();
  }

  return (
    <AnimatePresence mode="wait">
      <motion.div
        className="overflow-y-auto w-full max-h-[90vh] max-w-[720px] no-scrollbar relative"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        {renderHeader()}

        <AnimatePresence mode="wait">{renderStepContent()}</AnimatePresence>

        <motion.div
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: "spring", stiffness: 150, duration: 0.15 }}
          className="fixed bottom-0 left-0 right-0 z-50"
        >
          <TotalBar
            currentStep={currentStep}
            onContinue={handleContinue}
            buttonText={currentStep === 4 ? "Enviar pedido" : "Continuar"}
          />
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
};

export default QuickCart;
