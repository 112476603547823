import { Button } from "@radix-ui/themes";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Set HTTP status code to 404
    document.title = "404 - Página no encontrada | Fluenti";
    
    const metaRobots = document.createElement('meta');
    metaRobots.name = 'robots';
    metaRobots.content = 'noindex';
    document.head.appendChild(metaRobots);

    const metaStatus = document.createElement('meta');
    metaStatus.httpEquiv = 'status';
    metaStatus.content = '404';
    document.head.appendChild(metaStatus);
    
    return () => {
      document.head.removeChild(metaRobots);
      document.head.removeChild(metaStatus);
    };
  }, []);

  function handleGoHome() {
    navigate("/");
  }

  return (
    <div
      className="flex flex-col items-center justify-between h-screen w-screen bg-cover bg-center bg-no-repeat"
      style={{
        backgroundImage: `url("https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/oasis-fluenti-smartwater-404.jpg")`,
      }}
    >
      <div className="flex flex-col items-center justify-between h-full p-16 text-center">
        <img
          src="assets/logos/fluenti-logo-black-140.svg"
          alt="Fluenti Logo"
          className="h-16 w-auto"
        />
        <div className="flex flex-col gap-2">
          <h1 className="text-6xl font-medium p-0 m-0">404</h1>
          <p className="text-lg">
            Lo sentimos.
            <br />
            No hemos encontrado la pagina que buscas.
          </p>
          <Button onClick={handleGoHome}>Ir al inicio</Button>
        </div>
      </div>
      <div className="h-22" />
    </div>
  );
};

export default PageNotFound;