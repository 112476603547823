import React, { useEffect } from "react";
import QuickCart from "../store/QuickCart";
import Navbar from "../navigation/Navbar";
import { useSalesContext } from "../../contexts/SalesContext";

const RentSug = () => {

  useEffect(() => {
  }, []);

  return (
    <div className="flex flex-col h-screen overflow-hidden">
      <Navbar type="new-order" staticPosition={true} />
      <div className="flex-1 overflow-hidden">
        <div className="h-full overflow-y-hidden lg:mx-40 bg-white flex justify-center min-w-[720px]">
          <QuickCart />
        </div>
      </div>
    </div>
  );
};

export default RentSug;
