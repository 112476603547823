import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import supabase from "../SupaCredentials";

export const SupaContext = createContext();
export const useSupaContext = () => useContext(SupaContext);

export const SupaProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [user, setUser] = useState(null);
  const [isActiveUser, setIsActiveUser] = useState(false);


  // --------------------------------------
  // Función de Pre-Registro de Usuario
  // --------------------------------------

  // --------------------------------------
  // Inicio de Sesión
  // --------------------------------------
  const login = useCallback(async (email, password) => {
    setLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw error;
      setUser(data.user);
      setIsActiveUser(true);
      return data.user;
    } catch (error) {
      console.error("Error al iniciar sesión:", error.message);
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  }, []);

  // --------------------------------------
  // Cierre de Sesión
  // --------------------------------------
  const logout = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      setUser({});
      setIsActiveUser(false);
    } catch (error) {
      console.error("Error al cerrar sesión:", error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  // --------------------------------------
  // EFECTO DE  Manejo del Estado de Autenticación
  // --------------------------------------

  const [authInitialized, setAuthInitialized] = useState(false);

  // In your authentication useEffect
  useEffect(() => {
    const checkCurrentSession = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
        
        if (error) {
          console.error("Session retrieval error:", error.message);
          setUser(null);
          setIsActiveUser(false);
        } else if (session) {
          setUser(session.user);
          setIsActiveUser(true);
        } else {
          console.log("No active session found");
          setUser(null);
          setIsActiveUser(false);
        }
      } catch (err) {
        console.error("Unexpected authentication error:", err);
        setUser(null);
        setIsActiveUser(false);
      } finally {
        setAuthInitialized(true);
      }
    };
    
    checkCurrentSession();
  
    const { data: authListener } = supabase.auth.onAuthStateChange(
      (event, session) => {
        console.log(`Auth state changed: ${event}`);
        
        if (session) {
          setUser(session.user);
          setIsActiveUser(true);
        } else {
          setUser(null);
          setIsActiveUser(false);
        }
      }
    );
  
    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  // --------------------------------------
  // Función para crear una dirección
  // --------------------------------------
  const createAddressAfterCreateUser = async (addressData) => {
    setLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from("addresses")
        .insert([
          {
            user_id: addressData.user_id,
            city: addressData.city,
            state: addressData.state,
            country: addressData.country,
            latitude: addressData.latitude,
            longitude: addressData.longitude,
            address: addressData.address,
            details: addressData.details,
            pre_registration: addressData.pre_registration,
          },
        ])
        .select();
      if (error) throw error;
      return data[0];
    } catch (error) {
      console.error("Error al crear la dirección:", error.message);
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------------
  // Funcion para crear una suscripcion
  // --------------------------------------
  const createSubscription = async (subscriptionData) => {
    try {
      const { userId, currentSugId, addressId, order_id } = subscriptionData;

      // Verificar si el usuario existe en pre_registrations
      const { data: userExists, error: userCheckError } = await supabase
        .from("pre_registrations")
        .select("id")
        .eq("id", userId)
        .single();

      if (userCheckError) {
        console.error("Error checking user existence:", userCheckError);
        throw new Error("Failed to verify user existence");
      }

      if (!userExists) {
        throw new Error("User ID does not exist in pre_registrations");
      }

      const startDate = new Date();
      const nextRenewalDate = new Date(startDate);
      nextRenewalDate.setMonth(nextRenewalDate.getMonth() + 1);

      const nextBottleChangeDate = new Date(startDate);
      nextBottleChangeDate.setMonth(nextBottleChangeDate.getMonth() + 3);

      const { data, error } = await supabase
        .from("subscriptions")
        .insert([
          {
            user_id: userId,
            current_sug_id: currentSugId,
            original_sug_id: currentSugId,
            start_date: startDate.toISOString(),
            status: "deal_pending",
            address_id: addressId,
            order_id: order_id
          },
        ])
        .select();

      if (error) throw error;

      return data[0];
    } catch (error) {
      console.error("Error adding subscription:", error);
      throw error;
    }
  };

  // --------------------------------------
  // Funcion para creal multiples suscripciones
  // --------------------------------------
  const createSubscriptionsForSugs = async (sugs, baseData) => {
    for (const sug of sugs) {

      const subscriptionData = {
        ...baseData,
        currentSugId: sug.id, // Make sure this matches the property name in createSubscription
      };

      try {
        const result = await createSubscription(subscriptionData);
      } catch (error) {
        console.error("Error al crear la suscripción para", sug.id, error);
      }
    }
    return true
  };

  // --------------------------------------
  // Función para crear una orden
  // --------------------------------------
  const createOrder = async (orderData) => {
    setLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from("orders")
        .insert([
          {
            user_id: orderData.userId,
            public_id: orderData.public_id,
            total: orderData.total,
            terms: true,
            status: "pending",
          },
        ])
        .select();

      if (error) throw error;
      return data[0];
    } catch (error) {
      console.error("Error al crear la orden:", error.message);
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------------
  // Función para crear un solo item en una orden
  // --------------------------------------
  const createOrderItem = async (item) => {
    setLoading(true);
    setError(null);
    try {
      const { data, error } = await supabase
        .from("order_items")
        .insert({
          order_id: item.order_id,
          order_public_id: item.order_public_id,
          item_type: item.type || item.item_type,
          item_id: item.id || item.item_id,
          qty: item.qty,
          value: item.value,
        })
        .select();

      if (error) throw error;
      return data;
    } catch (error) {
      console.error("Error al crear los items de la orden:", error.message);
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------------
  // Crear multiples items usando la funcion anterior
  // --------------------------------------

  const createMultipleOrderItems = async (baseItem, itemIds) => {
    setLoading(true);
    setError(null);
    const results = [];
    const errors = [];

    try {
      // Creamos un array de promesas para procesar todos los items en paralelo
      const itemPromises = itemIds.map(async (itemId) => {

        // Combinamos la base con el id específico
        const newItem = {
          ...baseItem,
          item_id: itemId,
        };

        try {
          const result = await createOrderItem(newItem);
          if (result) {
            results.push(result);
          }
        } catch (error) {
          errors.push({ itemId, error: error.message });
        }
      });

      // Esperamos a que todas las promesas se resuelvan
      await Promise.all(itemPromises);

      // Si hay errores pero también hay resultados exitosos, continuamos pero guardamos los errores
      if (errors.length > 0) {
        console.warn("Algunos items no pudieron ser creados:", errors);
        setError(`${errors.length} items fallaron al crearse`);
      }

      return {
        success: results.length > 0,
        data: results,
        errors: errors.length > 0 ? errors : null,
      };
    } catch (error) {
      console.error("Error general al crear los items:", error.message);
      setError(error.message);
      return {
        success: false,
        data: null,
        errors: [error.message],
      };
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------------
  // Valores del Contexto
  // --------------------------------------

  const createOrderItemsWithSugs = async (order, sugs, baseItemData) => {
    setLoading(true);
    setError(null);
    try {
      // Validaciones mejoradas según la estructura de la tabla
      if (!order?.id || !order?.public_id) {
        throw new Error("Se requiere order.id y order.public_id");
      }

      if (!sugs || !Array.isArray(sugs) || sugs.length === 0) {
        throw new Error("Se requiere un array válido de sugs");
      }

      if (!baseItemData?.item_type || typeof baseItemData.value !== "number") {
        throw new Error("baseItemData debe incluir item_type y value numérico");
      }

      // Preparar los items según la estructura exacta de la tabla
      const items = sugs.map((sug) => ({
        order_id: order.id,
        order_public_id: order.public_id,
        item_type: baseItemData.item_type,
        item_id: sug.id,
        qty: baseItemData.qty || 1,
        value: Number(baseItemData.value), // Aseguramos que sea numérico
      }));

      const { data, error } = await supabase
        .from("order_items")
        .insert(items)
        .select();

      if (error) {
        console.error("Error en Supabase:", error);
        throw error;
      }

      return data;
    } catch (error) {
      console.error("Error al crear order items:", error.message);
      setError(error.message);
      return null;
    } finally {
      setLoading(false);
    }
  };

  // --------------------------------------
  // Agregar recambios
  // --------------------------------------
  const createRefill = async (refillData) => {
    try {
      const { data, error } = await supabase
        .from("refills")
        .insert([
          {
            user_id: refillData.userId,
            order_id: refillData.orderId,
            status: refillData.status || "deal_pending",
            purchase_date: refillData.purchaseDate || new Date().toISOString(),
            used_date: refillData.usedDate || null,
            used_subscription: refillData.usedSubscription || null,
          },
        ])
        .select();

      if (error) throw error;
      return { success: true, data };
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  // --------------------------------------
  // AGREGAR VARIOS RECAMBIOS EN AUTO
  // --------------------------------------

  const createBulkRefills = async (qty, userId, orderId) => {
    try {
      // Creamos un array con la cantidad especificada de objetos idénticos
      const refills = Array(qty).fill({
        user_id: userId,
        order_id: orderId,
        status: "deal_pending",
      });

      const { data, error } = await supabase
        .from("refills")
        .insert(refills)
        .select("id");

      if (error) throw error;

      return {
        success: true,
        count: qty,
        refillIds: data.map((refill) => refill.id),
        // Incluimos también el array completo de datos por si es necesario
        data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.message,
        refillIds: [],
        count: 0,
      };
    }
  };

  // --------------------------------------
  // Subir imagen a supabase
  // --------------------------------------
  async function saveImageToBucket(file, imageId) {
    try {
      const fileName = `${imageId}.png`
      
      const { data, error } = await supabase.storage
        .from('addresses_captures')
        .upload(fileName, file, {
          contentType: 'image/png',
          upsert: true,
        })
  
      if (error) throw error
  
      const { data: publicUrlData } = supabase.storage
        .from('addresses_captures')
        .getPublicUrl(fileName)
  
      return publicUrlData.publicUrl
    } catch (error) {
      console.error('Error al guardar la imagen:', error)
      return null
    }
  }
  

  // --------------------------------------
  // Nueva función para obtener roles de usuario
  // --------------------------------------
  const getUserRoles = async (userId) => {
    if (!userId) {
      console.error("Error: No user ID provided for role verification");
      return [];
    }
    
    try {
      const { data, error } = await supabase
        .from("users_roles")
        .select("role")
        .eq("user_id", userId);
      
      if (error) {
        console.error("Database error when fetching roles:", error);
        return [];
      }
      
      // Extract just the role values from each record
      const roles = data.map(record => record.role);
      
      return roles;
    } catch (error) {
      console.error("Unexpected error in getUserRoles:", error.message);
      return [];
    }
  };

 // --------------------------------------
// Función para crear datos de usuario en user_data
// --------------------------------------
const createUserData = async (userData) => {
  try {
    const { data, error } = await supabase
      .from("user_data")
      .insert([
        {
          id: userData.id,
          first_name: userData.first_name,
          last_name: userData.last_name,
          phone: userData.phone,
          email: userData.email,
          has_account: userData.has_account || true,
          coming_from: userData.coming_from || "direct_registration",
          nationality: userData.nationality,
          document_type: userData.document_type,
          document_value: userData.document_value
        },
      ])
      .select();
    
    if (error) throw error;
    return data[0];
  } catch (error) {
    console.error("Error al crear datos de usuario:", error.message);
    throw error;
  }
};

// --------------------------------------
// Función de Registro de Usuario
// --------------------------------------
const registerUser = async (email, password, userData) => {
  setLoading(true);
  setError(null);
  try {
    // Registrar usuario con auth
    const { data: authData, error: authError } = await supabase.auth.signUp({
      email,
      password,
    });
    
    if (authError) throw authError;
    
    // Si el registro fue exitoso, proceder a guardar los datos en user_data
    if (authData.user) {
      // Crear registro en user_data con el ID generado por auth
      const userDataResult = await createUserData({
        id: authData.user.id,
        ...userData,
        email: email,
        has_account: true,
      });
      
      if (userDataResult) {
        return {
          success: true,
          user: authData.user,
          userData: userDataResult
        };
      }
    }
    
    return {
      success: false,
      error: "Error al crear el usuario"
    };
  } catch (error) {
    console.error("Error al registrar usuario:", error.message);
    setError(error.message);
    return {
      success: false,
      error: error.message
    };
  } finally {
    setLoading(false);
  }
};

  // --------------------------------------
  // Valores del Contexto
  // --------------------------------------
  const contextValue = {
    user,
    login,
    logout,
    loading,
    isActiveUser,
    error,
    createAddressAfterCreateUser,
    createSubscription,
    createSubscriptionsForSugs,
    createOrder,
    createOrderItem,
    createMultipleOrderItems,
    createOrderItemsWithSugs,
    createRefill,
    createBulkRefills,
    saveImageToBucket,
    getUserRoles,
    authInitialized,
    createUserData,
    registerUser
  };

  return (
    <SupaContext.Provider value={contextValue}>{children}</SupaContext.Provider>
  );
};