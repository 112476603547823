import { Button } from "@radix-ui/themes";
import React, { useState } from "react";
import Sidepanel from "../navigation/Sidepanel";

const CTAs = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  function goToHero() {
    const elementId = "hero";
    const element = document.getElementById(elementId);

    if (element) {
      const top = element.offsetTop;
      const downPosition = top + 200;
      const duration = 500; // tiempo en milisegundos para el desplazamiento suave

      // Desplaza hacia abajo 200px
      window.scrollTo({
        top: downPosition,
        behavior: "smooth",
      });

      // Espera un poco antes de regresar al top
      setTimeout(() => {
        window.scrollTo({
          top: top,
          behavior: "smooth",
        });
      }, duration); // Espera el tiempo de desplazamiento antes de regresar
    }
  }

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const bannersImages = {
    slots:
      "/assets/img/landing/heroBgAbstract.jpg",
    disscount:
      "https://vacewaenygdaqchlrsam.supabase.co/storage/v1/object/public/web-landing/slots-banner-plan-recambios-botellon-20l-dispensador-pedidos-online-entrega-domicilio-agua-purificada-calidad.jpg",
  };

  const DiscountSection = () => (
    <div
      className="flex flex-col items-center justify-center gap-3 p-3 max-w-[1024px] rounded-[40px] min-h-[360px] "
      style={{
        backgroundImage: `url(${bannersImages.slots})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <p className="p-0 m-0 w-full text-[40px] font-light text-center text-[#f0f0f0] leading-[96%]">
        Ahorra entre el{" "}
        <b className="text-white font-normal">5% y 30% por botellón</b>{" "}
      </p>
      <p className="text-[20px] font-normal text-white text-center w-full">
        De $500 a $7.000 comparado con marcas/servicios similares.
      </p>

      <div>
        <Button
          size="3"
          className="bg-gray-800 text-white font-medium text-lg"
          onClick={() => goToHero()}
        >
          Pide tu botellón
        </Button>
      </div>
    </div>
  );

  const DeliverySlots = () => (
    <div
      className="flex flex-col items-start justify-center gap-3 p-8 max-w-[1024px] rounded-[40px] min-h-[360px] bg-[#d9e8ff] border border-[#eaeaea]  "
      style={{
        backgroundImage: `url(${bannersImages.disscount})`,
        backgroundSize: "cover",
        backgroundPosition: "left",
        backgroundRepeat: "no-repeat",
        minHeight: "360px",
        backgroundColor: "#d9e8ff",
        border: "#eaeaea 1px solid",
      }}
    >
      <p className="p-0 m-0 w-3/4 text-[40px] font-medium text-left text-[#222] leading-[96%]">
        ¡Domicilios programados gratis!
      </p>
      <p className="text-[20px] font-normal text-[#222] text-left w-3/4">
        De lunes a sábados, recibe por la mañana ó por la tarde.
      </p>
      <div>
        <Button
          size="3"
          className="font-normal text-lg bg-gray-800 text-white"
          onClick={() => goToHero()}
        >
          Empezar hoy
        </Button>
      </div>
    </div>
  );

  const locations = [
    // "Alameda del Rio",
    // "Miramar",
    // "Tabor",
    // "Nogales",
    // "Campo Alegre",
    // "Villa Carolina",
    // "Paraiso",
    // "Norte-Centro Histórico",
    "Centro",
    "Via 40",
    "El Prado",
    "Modelo",
    "Bella Vista",
    "Montecristo",
    "Barrio abajo",
    "El Rosario",
    "Parque Central",
    "Boston",
    "Olaya",
    "Recreo",
    "Betania",
    "Las Delicias",
    "Colombia",
    "San Felipe",
    "Los Andes",
    "Los Pinos",
    "Nueva Granada",
    "Chiquinquirá",
    "Alfonso López",
    "Lucero",
    "El Carmén",
    "San Isidro",
    "San José",
    "San Roque",
    "Atlántico",
  ];

  const LocationsTexts = () => (
    <div className="max-w-[1024px] font-light text-3xl locationsTexts flex flex-wrap gap-4">
      <span className="text-[#1464ff]">Domicilio en Barranquilla</span>
      {locations.map((location, index) => (
        <div
          key={index}
          className={`flex justify-center items-center hover:scale-110 transition duration-50 ease-in-out`}
        >
          {location} •
        </div>
      ))}
      <span
        className="text-[#1464ff] cursor-pointer"
        onClick={() => setIsSidebarOpen(true)}
      >
        Ver cobertura
      </span>
    </div>
  );

  return (
    <div className="flex-col px-8 flex gap-16">
      <DiscountSection />
      <DeliverySlots />
      <LocationsTexts />
      <Sidepanel
        isOpen={isSidebarOpen}
        onClose={toggleSidebar}
        type={"coverage"}
      />
    </div>
  );
};

export default CTAs;
