export function generateJugDummy() {
  const id = generateUUID();
  const userId = `user-${Math.floor(Math.random() * 1000)}`;
  const addressId = `address-${Math.floor(Math.random() * 1000)}`;
  const familyId = `family-${Math.floor(Math.random() * 1000)}`;
  const capacity = Math.random() * 10 + 1;
  const material = getRandomMaterial();
  const status = getRandomStatus();
  const type = getRandomType();
  const purchaseDate = getRandomDate();
  const expirationDate = getRandomDate();
  const createdAt = getRandomDateTime();
  const updatedAt = createdAt;

  return {
    id,
    user_id: userId,
    address_id: addressId,
    family_id: familyId,
    capacity: capacity.toFixed(2),
    material,
    status,
    type,
    purchase_date: purchaseDate,
    expiration_date: expirationDate,
    created_at: createdAt,
    updated_at: updatedAt,
  };
}

export function generateUUID() {
  return `${Math.floor(Math.random() * 1000000000).toString(16)}`;
}

function getRandomMaterial() {
  const materials = ["Glass", "Plastic", "Metal", "Ceramic"];
  return materials[Math.floor(Math.random() * materials.length)];
}

function getRandomStatus() {
  const statuses = ["free", "assigned", "in_use"];
  return statuses[Math.floor(Math.random() * statuses.length)];
}

function getRandomType() {
  const types = ["purchase", "rental"];
  return types[Math.floor(Math.random() * types.length)];
}

function getRandomDate() {
  const date = new Date();
  const year = date.getFullYear();
  const month = Math.floor(Math.random() * 12) + 1;
  const day = Math.floor(Math.random() * 28) + 1;
  return `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")}`;
}

function getRandomDateTime() {
  const date = new Date();
  const year = date.getFullYear();
  const month = Math.floor(Math.random() * 12) + 1;
  const day = Math.floor(Math.random() * 28) + 1;
  const hour = Math.floor(Math.random() * 24);
  const minute = Math.floor(Math.random() * 60);
  const second = Math.floor(Math.random() * 60);
  return `${year}-${month.toString().padStart(2, "0")}-${day
    .toString()
    .padStart(2, "0")} ${hour.toString().padStart(2, "0")}:${minute
    .toString()
    .padStart(2, "0")}:${second.toString().padStart(2, "0")}`;
}

export function formatPrice(price) {
  if (typeof price === "string") {
    price = parseFloat(price.replace(/[^\d\.]/g, ""));
  }
  return price.toLocaleString("es-ES", { useGrouping: true, separator: "." });
}

export const processGeoJSON = (geoJSON) => {
  try {
    const features = geoJSON.features;
    if (!features || features.length === 0) {
      throw new Error("No se encontraron características en el GeoJSON");
    }

    const polygonFeature = features.find(
      (feature) => feature.geometry.type === "Polygon"
    );
    if (!polygonFeature) {
      throw new Error("No se encontró un polígono en el GeoJSON");
    }

    const coordinates = polygonFeature.geometry.coordinates[0];
    const polygonPath = coordinates.map((coord) => ({
      lat: coord[1],
      lng: coord[0],
    }));

    // Calcular el centro del polígono
    const sumLat = polygonPath.reduce((sum, coord) => sum + coord.lat, 0);
    const sumLng = polygonPath.reduce((sum, coord) => sum + coord.lng, 0);
    const center = {
      lat: sumLat / polygonPath.length,
      lng: sumLng / polygonPath.length,
    };

    return {
      polygonPath,
      center,
    };
  } catch (error) {
    console.error("Error al procesar el GeoJSON:", error);
    return null;
  }
};

export function isValidEmail(email) {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(email);
}

export function handlePhoneChange(event, setPhone) {
  let input = event.target.value;

  // Si el input ya tiene "+57", lo eliminamos para procesarlo
  if (input.startsWith("+57")) {
    input = input.slice(3).trim();
  }

  // Limpiamos el input dejando solo los números
  const numbers = input.replace(/\D/g, "");

  // Si el usuario intenta borrar y queda vacío, permitimos que el campo se limpie
  if (numbers === "") {
    setPhone("+57");
    return;
  }

  // Verificamos si el número comienza con 3, solo si hay contenido
  if (!numbers.startsWith("3") && numbers.length > 0) {
    return; // Salimos sin actualizar el estado si no empieza con "3"
  }

  // Formateamos el número dejando "+57" fijo y agregando los espacios
  let formatted = "+57";
  if (numbers.length > 0) {
    formatted += " " + numbers.slice(0, 3); // Los primeros 3 dígitos
  }
  if (numbers.length >= 4) {
    formatted += " " + numbers.slice(3, 6); // Los siguientes 3 dígitos
  }
  if (numbers.length >= 7) {
    formatted += " " + numbers.slice(6, 10); // Los últimos 4 dígitos
  }

  setPhone(formatted);
}


export function handleEmailChange(event, setEmail) {
  const newEmail = event.target.value;
  setEmail(newEmail);

  // You can add more complex validation if needed
  if (isValidEmail(newEmail)) {
    // Email is valid, you can update other states or perform actions
  } else {
    // Email is invalid, you can set an error state or show a message
  }
}

export const capitalizeWords = (str) => {
  return str.replace(
    /\b\w{3,}/g,
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
};

export const handleNameChange = (event, setName) => {
  const input = event.target.value;
  const sanitizedInput = input.replace(/[^a-zA-Z\s]/g, "");
  const capitalizedInput = capitalizeWords(sanitizedInput);
  setName(capitalizedInput);
};

export function cleanStringNumber(string) {
  return string.replace(/\D+/g, "").replace(/\s+/g, "");
}

export const generateEpochUniqueId = (() => {
  let lastTimestamp = 0;
  let sequence = 0;

  return () => {
    let timestamp = new Date();

    if (timestamp === lastTimestamp) {
      sequence++;
    } else {
      sequence = 0;
      lastTimestamp = timestamp;
    }

    // Combina el timestamp con la secuencia
    const combined = timestamp * 1000 + sequence;

    // Convierte a base 36, toma los últimos 8 caracteres, rellena con ceros y convierte a mayúsculas
    return combined.toString(36).slice(-8).padStart(8, "0").toUpperCase();
  };
})();

export const sendLeadToWebhook = async (WEBHOOK_URL, leadData) => {
  try {
    const response = await fetch(WEBHOOK_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(leadData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

  } catch (error) {
    console.error("Error al enviar el lead al webhook:", error);
  }
};

// Nuevas funciones
export const loadGoogleMapsApi = (apiKey) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places,geometry`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);
    script.onload = resolve;
  });
};

export const initializeGoogleServices = (polygonData) => {
  const autocompleteService = new window.google.maps.places.AutocompleteService();
  const placesService = new window.google.maps.places.PlacesService(document.createElement("div"));
  const polygonCoords = polygonData.features[0].geometry.coordinates[0].map(
    (coord) => new window.google.maps.LatLng(coord[1], coord[0])
  );
  const polygon = new window.google.maps.Polygon({ paths: polygonCoords });
  return { autocompleteService, placesService, polygon };
};

export const fetchMapImage = async (coordinates, apiKey) => {
  const mapUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${coordinates.lat},${coordinates.lng}` +
    `&zoom=18&size=640x420&maptype=roadmap&markers=color:red%7C${coordinates.lat},${coordinates.lng}&key=${apiKey}`;
  const response = await fetch(mapUrl);
  if (!response.ok) throw new Error(`Error en la solicitud: ${response.statusText}`);
  return await response.blob();
};

export const validateLocation = (coordinates, polygon) => {
  const latLng = new window.google.maps.LatLng(coordinates.lat, coordinates.lng);
  return window.google.maps.geometry.poly.containsLocation(latLng, polygon);
};

export const focusAddressDetails = (ref) => {
  setTimeout(() => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    ref.current?.focus();
  }, 400);
};

export const handleInvalidLocation = async (
  coordinates,
  selectedSuggestion,
  userData,
  webhookUrl,
  addPreRegistration,
  createAddressAfterCreateUser,
  addressDetails
) => {
  const leadData = {
    ...userData,
    selectedCoordinates: coordinates,
    timestamp: new Date().toISOString(),
  };

  try {
    await sendLeadToWebhook(webhookUrl, leadData);
    const preRegistrationData = {
      first_name: userData.name.split(" ")[0],
      last_name: userData.name.split(" ").slice(1).join(" ") || "Desconocido",
      phone: userData.phone,
      email: userData.email,
    };
    const preRegisteredUser = await addPreRegistration(preRegistrationData);
    if (preRegisteredUser && preRegisteredUser.id) {
      const addressData = {
        user_id: preRegisteredUser.id,
        city: "Barranquilla",
        state: "ATL",
        country: "Colombia",
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        address: selectedSuggestion,
        details: addressDetails,
        pre_registration: true,
      };
      await createAddressAfterCreateUser(addressData);
    } else {
      console.error("No se pudo completar el pre-registro o no se obtuvo un ID de usuario");
    }
  } catch (error) {
    console.error("Error en handleInvalidLocation:", error.message);
  }
};
export const handleCorrectLocationLead = async (
  coordinates,
  selectedSuggestion,
  userData,
  webhookUrl,
  addPreRegistration,
  createAddressAfterCreateUser,
  addressDetails
) => {
  const leadData = {
    ...userData,
    selectedCoordinates: coordinates,
    timestamp: new Date().toISOString(),
  };

  try {
    await sendLeadToWebhook(webhookUrl, leadData);
    const preRegistrationData = {
      first_name: userData.name.split(" ")[0],
      last_name: userData.name.split(" ").slice(1).join(" ") || "Desconocido",
      phone: userData.phone,
      email: userData.email,
    };
    const preRegisteredUser = await addPreRegistration(preRegistrationData);
    if (preRegisteredUser && preRegisteredUser.id) {
      const addressData = {
        user_id: preRegisteredUser.id,
        city: "Barranquilla",
        state: "ATL",
        country: "Colombia",
        latitude: coordinates.lat,
        longitude: coordinates.lng,
        address: selectedSuggestion,
        details: addressDetails,
        pre_registration: true,
      };
      await createAddressAfterCreateUser(addressData);
    } else {
      console.error("No se pudo completar el pre-registro o no se obtuvo un ID de usuario");
    }
  } catch (error) {
    console.error("Error en handleInvalidLocation:", error.message);
  }
};

export const getOperationalStateColor = (state) => {
  if (state === "recently_ordered" || state === "on_production")
    return "bg-blue-500 text-white";
  if (state === "to_be_returned" || state === "overdue_for_return")
    return "bg-red-500 text-white";
  if (state === "in_inventory" || state === "refill_base")
    return "bg-green-500 text-white";
  if (
    [
      "ready_for_production",
      "in_washing",
      "in_filling",
      "ready_for_delivery",
    ].includes(state)
  )
    return "bg-purple-500 text-white";
  return "bg-gray-500 text-white";
};

// Función para formatear estados en texto más legible
export const formatState = (state) => {
  if (!state) return "";
  return state
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

// Obtener color de badge según estado físico
export const getPhysicalStateColor = (state) => {
  const colors = {
    new: "bg-blue-500 text-white",
    excellent: "bg-green-500 text-white",
    good: "bg-green-300 text-white",
    regular: "bg-yellow-500 text-white",
    deteriorated: "bg-orange-500 text-white",
    discarded: "bg-red-500 text-white",
  };
  return colors[state] || "bg-gray-500 text-white";
};

// Formato de fecha para mostrar
export const formatDate = (dateString) => {
  if (!dateString) return "No asignada";
  const date = new Date(dateString);
  return date.toLocaleDateString("es-CO", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

// Formato de fecha y hora para mostrar
export const formatDateTime = (dateString) => {
  if (!dateString) return "No registrada";
  const date = new Date(dateString);
  return date.toLocaleDateString("es-CO", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};

// Obtener nombre completo del cliente
export const getCustomerName = (userData) => {
  if (!userData) return "Cliente no asignado";
  return `${userData.first_name} ${userData.last_name}`;
};

// Calcular días desde la entrega
export const getDaysSinceDelivery = (deliveryDate) => {
  if (!deliveryDate) return "N/A";
  const delivered = new Date(deliveryDate);
  const today = new Date();
  const diffTime = today - delivered;
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  return diffDays;
};

// Opciones de estado operacional
export const statusOptions = [
  { value: "recently_ordered", label: "Recién pedido", icon: "📋" },
  { value: "ready_for_production", label: "Cola de producción", icon: "⏳" },
  { value: "in_washing", label: "En lavado", icon: "🧼" },
  { value: "in_filling", label: "En llenado", icon: "🚰" },
  { value: "ready_for_delivery", label: "Listo para entrega", icon: "📦" },
  { value: "in_transit", label: "En tránsito", icon: "🚚" },
  { value: "delivered", label: "Entregado", icon: "✅" },
  { value: "not_delivered", label: "No entregado", icon: "❌" },
  { value: "rejected", label: "Rechazado", icon: "🚫" },
  { value: "to_be_refilled", label: "Para recambio", icon: "🔄" },
  { value: "to_be_returned", label: "Para devolución", icon: "↩️" },
  { value: "returning", label: "Retornando", icon: "⬅️" },
  { value: "expired", label: "Vencido", icon: "⏱️" },
  { value: "overdue_for_return", label: "Devolución vencida", icon: "⚠️" },
];

// Opciones de estado de pago
export const paymentStatusOptions = [
  { value: "pending", label: "Pendiente", icon: "⏳" },
  { value: "processing", label: "Procesando", icon: "⚙️" },
  { value: "paid", label: "Pagado", icon: "💰" },
  { value: "partially_paid", label: "Pago parcial", icon: "💸" },
  { value: "failed", label: "Fallido", icon: "❌" },
  { value: "refunded", label: "Reembolsado", icon: "↩️" },
  { value: "cancelled", label: "Cancelado", icon: "🚫" },
];

// Estados de pago que no se pueden cambiar
export const nonEditablePaymentStates = ["paid", "cancelled", "refunded"];

// Orden de los estados operacionales para validación
export const operationalStateOrder = [
  "recently_ordered",
  "ready_for_production",
  "in_washing",
  "in_filling",
  "ready_for_delivery",
  "in_transit",
  "delivered",
  "not_delivered",
  "rejected",
  "to_be_refilled",
  "to_be_returned",
  "returning",
  "expired",
  "overdue_for_return",
];