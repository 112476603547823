import React from "react";
import { motion } from "framer-motion";
import InputField from "./InputField";

const SignInForm = ({ signInData, handleChange, handleSubmit, loading }) => {
  const isSignInValid = signInData.email && signInData.password;

  return (
    <motion.form
      onSubmit={handleSubmit}
      initial={{ opacity: 0, x: 20 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -20 }}
      transition={{ duration: 0.2 }}
      className="space-y-6"
    >
      <div className="py-4 space-y-4 ">
      <InputField
        value={signInData.email}
        onChange={(e) => handleChange("email")(e)}
        placeholder="Ingresa tu correo electrónico"
        type="email"
        name="email"
      />
        <InputField
          value={signInData.password}
          onChange={(e) => handleChange("password")(e)}
          placeholder="Ingresa tu contraseña"
          isPassword={true}
          name="current-password"
        />
      </div>

      <button
        type="submit"
        disabled={!isSignInValid || loading}
        className="w-full bg-blue-600 border-none text-white py-3 rounded-2xl hover:bg-blue-800 font-['Fredoka'] transition font-medium text-lg disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {loading ? "Iniciando sesión..." : "Iniciar Sesión"}
      </button>

      <a
        href="/"
        className="block text-center text-blue-600 hover:text-blue-700 text-sm"
      >
        ¿Olvidaste tu contraseña?
      </a>
    </motion.form>
  );
};

export default SignInForm;
