import React, { useState, useEffect } from "react";
import * as Popover from "@radix-ui/react-popover";
import { ChevronDown } from "lucide-react";



const NumbersSelect = ({
  label,
  minNumber,
  maxNumber,
  setCounter,
  counter,
}) => {
  useEffect(() => {
    setCounter(minNumber);
  }, [minNumber]);

  const handleNumberClick = (number) => {
    setCounter(number);
    if (setCounter) {
      setCounter(number);
    }
  };

  const renderNumbers = () => {
    const numbers = [];
    for (let i = minNumber; i <= Math.min(maxNumber, minNumber + 3); i++) {
      numbers.push(
        <button
          key={i}
          onClick={() => handleNumberClick(i)}
          className={`w-10 h-10 rounded-xl font-['Fredoka'] border-none flex items-center justify-center text-lg transition-colors ${
            counter === i
              ? "text-blue-600 bg-blue-200 font-medium"
              : "bg-gray-100 text-gray-700 hover:bg-gray-200"
          }`}
        >
          {i}
        </button>
      );
    }
    return numbers;
  };

  const renderPopoverContent = () => {
    return (
      <div className="grid grid-cols-4 gap-2 p-2 bg-white rounded-xl shadow-lg">
        {[...Array(maxNumber - minNumber + 1)].map((_, index) => (
          <button
            key={index + minNumber}
            onClick={() => handleNumberClick(index + minNumber)}
            className={`w-10 h-10 rounded-xl border-none font-['Fredoka'] flex items-center justify-center text-lg transition-colors ${
              counter === index + minNumber
                ? "text-blue-600 bg-blue-200 font-medium"
                : "bg-gray-100 text-gray-700 hover:bg-gray-200"

            }`}
          >
            {index + minNumber}
          </button>
        ))}
      </div>
    );
  };

  return (
    <div className="flex flex-col space-y-2  ">
      <label className="text-lg font-regular text-gray-800">{label}</label>
      <div className="flex items-center gap-2">
        {renderNumbers()}
        {maxNumber > minNumber + 3 && (
          <Popover.Root>
            <Popover.Trigger asChild>
              <button
                className={`w-auto min-w-[2.5rem] max-w-[40px] font-['Fredoka'] h-10 rounded-xl border-none flex items-center justify-center px-3 text-lg transition-colors ${
                  counter > minNumber + 3
                    ? "text-blue-600 bg-blue-200 font-medium"
                    : "bg-gray-100 text-gray-700 hover:bg-gray-200"

                }`}
              >
                {counter > minNumber + 3 ? (
                  <div className="flex flex-col gap-0 justify-center items-center">
                    <span className="p-0 m-0">{counter}</span>
                    <ChevronDown size={14} className="text-white my-[-8px]" />

                  </div>
                ) : (
                  <ChevronDown size={16} />
                )}
              </button>
            </Popover.Trigger>
            <Popover.Portal>
              <Popover.Content className="z-50" sideOffset={5}>
                {renderPopoverContent()}
                <Popover.Arrow className="fill-white" />
              </Popover.Content>
            </Popover.Portal>
          </Popover.Root>
        )}
      </div>
    </div>
  );
};

export default NumbersSelect;
